import React, { Component } from "react";
import firebase from "firebase";
import { db, auth } from "../../utils/firebase";

/**
 * Datetime picker
 */
import Datetime from "react-datetime";

/**
 * Components
 */
import Input from "../ui/input/input";
import TextArea from "../ui/input/textarea";
import Button from "../ui/button/button";
import Checkbox from "../ui/input/checkbox";

/**
 * Moment libary
 */
const moment = require("moment");

class AddSDR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summary_mqls: "",
            summary_appointments: "",
            summary_notes: "",
            telesales_talktime: "",
            telesales_dials: "",
            telesales_mqls: "",
            telesales_appointments: "",
            telesales_notes: "",
            linkedin_acceptedrequests: "",
            linkedin_engagements: "",
            linkedin_mqls: "",
            linkedin_appointments: "",
            linkedin_notes: "",
            emails_emailssent: "",
            emails_engagements: "",
            emails_mqls: "",
            emails_appointments: "",
            emails_notes: "",
            include_appointments: false,
            include_hot: false,
            include_moderate: false,
            include_low: false,
            leads_since: {},
        };
    }

    /**
     * Save new lead
     *
     * @function
     */
    saveSDR = async () => {
        /**
         * Get the client ID from props
         */
        const { client } = this.props;
        /**
         * Get the lead data from the state
         */
        const {
            summary_mqls,
            summary_appointments,
            summary_notes,
            telesales_talktime,
            telesales_dials,
            telesales_mqls,
            telesales_appointments,
            telesales_notes,
            linkedin_acceptedrequests,
            linkedin_engagements,
            linkedin_mqls,
            linkedin_appointments,
            linkedin_notes,
            emails_emailssent,
            emails_engagements,
            emails_mqls,
            emails_appointments,
            emails_notes,
            include_appointments,
            include_hot,
            include_moderate,
            include_low,
            leads_since,
        } = this.state;
        /**
         * Add the lead
         */
        await db
            .collection(`clients/${client}/leads`)
            .add({
                summary_mqls,
                summary_appointments,
                summary_notes,
                telesales_talktime,
                telesales_dials,
                telesales_mqls,
                telesales_appointments,
                telesales_notes,
                linkedin_acceptedrequests,
                linkedin_engagements,
                linkedin_mqls,
                linkedin_appointments,
                linkedin_notes,
                emails_emailssent,
                emails_engagements,
                emails_mqls,
                emails_appointments,
                emails_notes,
                include_appointments,
                include_hot,
                include_moderate,
                include_low,
                type: "1_SDR",
                leads_since: firebase.firestore.Timestamp.fromMillis(leads_since),
                created: firebase.firestore.FieldValue.serverTimestamp(),
                updated: firebase.firestore.FieldValue.serverTimestamp(),
                saved_by: auth.currentUser.uid,
            })
            .then((lead) => {
                return lead.id;
            });
        /**
         * Reset the state
         */
        this.setState(
            {
                summary_mqls: "",
                summary_appointments: "",
                summary_notes: "",
                telesales_talktime: "",
                telesales_dials: "",
                telesales_mqls: "",
                telesales_appointments: "",
                telesales_notes: "",
                linkedin_acceptedrequests: "",
                linkedin_engagements: "",
                linkedin_mqls: "",
                linkedin_appointments: "",
                linkedin_notes: "",
                emails_emailssent: "",
                emails_engagements: "",
                emails_mqls: "",
                emails_appointments: "",
                emails_notes: "",
                include_appointments: false,
                include_hot: false,
                include_moderate: false,
                include_low: false,
                leads_since: {
                    seconds: "",
                    readable: "",
                },
                record_date: {
                    seconds: "",
                    readable: "",
                },
            },
            () => {
                /**
                 * Hide the form
                 */
                this.props.dismiss();
            }
        );
    };

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };

    /**
     * Update the state with a formatted verison of the datetime picker result
     *
     * @function
     *
     * @param {Object} date Date object from the datetime picker
     */
    handleSinceChange = (date) => {
        /**
         * Set the state with the milliseconds representation of the date
         */
        this.setState({
            ...this.state,
            leads_since: moment(date).format("x"),
        });
    };

    /**
     * Handler for the tracker checkbox
     *
     * @function
     */
    includeToggle = (e) => {
        switch (e.target.id) {
            case "appointments":
                this.setState({
                    ...this.state,
                    include_appointments: !this.state.include_appointments,
                });
                break;
            case "hot":
                this.setState({
                    ...this.state,
                    include_hot: !this.state.include_hot,
                });
                break;
            case "moderate":
                this.setState({
                    ...this.state,
                    include_moderate: !this.state.include_moderate,
                });
                break;
            case "low":
                this.setState({
                    ...this.state,
                    include_low: !this.state.include_low,
                });
                break;
            default:
                return false;
        }
    };

    render() {
        return (
            <div className={["modal-form show"].join(" ")}>
                {/* Summary form fields */}
                <div className={["sdr-form", this.props.tab === "summary" ? "active" : ""].join(" ")}>
                    <Input id="summary_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.summary_mqls} onChange={this.handleInputChange} />
                    <Input id="summary_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.summary_appointments} onChange={this.handleInputChange} />
                    <TextArea id="summary_notes" placeholder="Campaign Summary / Notes" placeholderLabel={true} value={this.state.summary_notes} onChange={this.handleInputChange} />
                </div>

                {/* Telesales form fields */}
                <div className={["sdr-form", this.props.tab === "telesales" ? "active" : ""].join(" ")}>
                    <Input id="telesales_talktime" placeholder="Talk Time" placeholderLabel={true} value={this.state.telesales_talktime} onChange={this.handleInputChange} />
                    <Input id="telesales_dials" placeholder="Dials" placeholderLabel={true} value={this.state.telesales_dials} onChange={this.handleInputChange} />
                    <Input id="telesales_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.telesales_mqls} onChange={this.handleInputChange} />
                    <Input id="telesales_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.telesales_appointments} onChange={this.handleInputChange} />
                    <TextArea id="telesales_notes" placeholder="Telesales Notes" placeholderLabel={true} value={this.state.telesales_notes} onChange={this.handleInputChange} />
                </div>

                {/* Linkedin form fields */}
                <div className={["sdr-form", this.props.tab === "linkedin" ? "active" : ""].join(" ")}>
                    <Input id="linkedin_acceptedrequests" placeholder="Requests Accepted" placeholderLabel={true} value={this.state.linkedin_acceptedrequests} onChange={this.handleInputChange} />
                    <Input id="linkedin_engagements" placeholder="Engagements" placeholderLabel={true} value={this.state.linkedin_engagements} onChange={this.handleInputChange} />
                    <Input id="linkedin_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.linkedin_mqls} onChange={this.handleInputChange} />
                    <Input id="linkedin_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.linkedin_appointments} onChange={this.handleInputChange} />
                    <TextArea id="linkedin_notes" placeholder="Linkedin Notes" placeholderLabel={true} value={this.state.linkedin_notes} onChange={this.handleInputChange} />
                </div>

                {/* Email form fields */}
                <div className={["sdr-form", this.props.tab === "email" ? "active" : ""].join(" ")}>
                    <Input id="emails_emailssent" placeholder="Emails Sent" placeholderLabel={true} value={this.state.emails_emailssent} onChange={this.handleInputChange} />
                    <Input id="emails_engagements" placeholder="Engagements" placeholderLabel={true} value={this.state.emails_engagements} onChange={this.handleInputChange} />
                    <Input id="emails_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.emails_mqls} onChange={this.handleInputChange} />
                    <Input id="emails_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.emails_appointments} onChange={this.handleInputChange} />
                    <TextArea id="emails_notes" placeholder="Email Notes" placeholderLabel={true} value={this.state.emails_notes} onChange={this.handleInputChange} />
                </div>

                {/* Include leads from this date */}
                <Datetime inputProps={{ placeholder: "Fetch leads since" }} timeFormat={false} closeOnSelect={true} onChange={(date) => this.handleSinceChange(date)} />
                <Checkbox id="appointments" label="Include appointments" toggle={this.includeToggle} checked={this.state.include_appointments} />
                <Checkbox id="hot" label="Include hot leads" toggle={this.includeToggle} checked={this.state.include_hot} />
                <Checkbox id="moderate" label="Include moderate leads" toggle={this.includeToggle} checked={this.state.include_moderate} />
                <Checkbox id="low" label="Include low leads" toggle={this.includeToggle} checked={this.state.include_low} />

                {/* Save SDR report */}
                <Button onClick={this.saveSDR}>Save Notes</Button>
            </div>
        );
    }
}

export default AddSDR;
