import React, { Component } from "react";
import { db, fun } from "../../utils/firebase";

/**
 * Components
 */
import Input from "../ui/input/input";
import Button from "../ui/button/button";
import TextArea from "../ui/input/textarea";

class EditSDR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lead: {},
            staff: false,
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        /**
         * Map any props to our state
         */
        this.mapPropsToState();
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {

        /**
         * Get lead values from props
         */
        const { lead } = this.props;
        /**
         * If the lead values don't match what we have in state (it's a new lead click)
         */
        if (lead && lead.id !== this.state.id) {
            /**
             * Map across the new data to state
             */
            this.mapPropsToState();
        }
    }

    /**
     * Map across the prop values to state
     *
     * @function
     */
    mapPropsToState = () => {
        /**
         * Get lead values from props
         */
        const { lead } = this.props;
        /**
         * Move prop values into state
         */
        this.setState({ ...lead }, () => {
            /**
             * Get the name of the user who added the lead
             */
            this.fetchLeadAdder();
        });
    };

    /**
     * User the UId of the adder to get their name
     *
     * @function
     */
    fetchLeadAdder = () => {
        /**
         * Get the adders ID from state
         */
        const { saved_by } = this.state;
        /**
         * Get their details from the database
         */
        db.doc(`users/${saved_by}`)
            .get()
            .then((user) => {
                /**
                 * Make sure their document exists
                 */
                if (user.exists) {
                    /**
                     * Update the state with their name or email
                     */
                    this.setState({ lead_added_by: user.data().name || user.data().email });
                } else {
                    /**
                     * Update the state with a placeholder
                     */
                    this.setState({ lead_added_by: "a previous user" });
                }
            });
    };

    /**
     * Save lead with new data
     *
     * @function
     */
    saveLead = () => {
        /**
         * Deconstruct the clientID from rops
         */
        const { client } = this.props;
        /**
         * Deconstruct the lead data from state
         */
        const {
            id,
            summary_mqls,
            summary_appointments,
            summary_notes,
            telesales_talktime,
            telesales_dials,
            telesales_mqls,
            telesales_appointments,
            telesales_notes,
            linkedin_acceptedrequests,
            linkedin_engagements,
            linkedin_mqls,
            linkedin_appointments,
            linkedin_notes,
            emails_emailssent,
            emails_engagements,
            emails_mqls,
            emails_appointments,
            emails_notes,
        } = this.state;
        /**
         * Update the lead document
         */
        db.doc(`clients/${client}/leads/${id}`).set(
            {
                summary_mqls,
                summary_appointments,
                summary_notes,
                telesales_talktime,
                telesales_dials,
                telesales_mqls,
                telesales_appointments,
                telesales_notes,
                linkedin_acceptedrequests,
                linkedin_engagements,
                linkedin_mqls,
                linkedin_appointments,
                linkedin_notes,
                emails_emailssent,
                emails_engagements,
                emails_mqls,
                emails_appointments,
                emails_notes,
            },
            { merge: true }
        );
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
    };

    /**
     * Remove the lead
     *
     * @function
     */
    removeLead = () => {
        /**
         * Deconstruct the clientID from rops
         */
        const { client } = this.props;
        /**
         * Deconstruct the lead ID from state
         */
        const { id } = this.state;
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
        /**
         * Remove the lead document first
         */
        db.doc(`clients/${client}/leads/${id}`).delete();
        /**
         * Remove the lead document and engagements collection through a cloud function
         */
        const recDelete = fun.httpsCallable("recursiveDelete");
        recDelete({ path: `clients/${client}/leads/${id}` }).catch((err) => {
            console.log(err.message);
        });
    };

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };

    render() {
        return (
            <div className={["modal-form show"].join(" ")}>
                {/* Summary form fields */}
                <div className={["sdr-form", this.props.tab === "summary" ? "active" : ""].join(" ")}>
                    <Input id="summary_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.summary_mqls} onChange={this.handleInputChange} />
                    <Input id="summary_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.summary_appointments} onChange={this.handleInputChange} />
                    <TextArea id="summary_notes" placeholder="Campaign Summary / Notes" placeholderLabel={true} value={this.state.summary_notes} onChange={this.handleInputChange} />
                </div>

                {/* Telesales form fields */}
                <div className={["sdr-form", this.props.tab === "telesales" ? "active" : ""].join(" ")}>
                    <Input id="telesales_talktime" placeholder="Talk Time" placeholderLabel={true} value={this.state.telesales_talktime} onChange={this.handleInputChange} />
                    <Input id="telesales_dials" placeholder="Dials" placeholderLabel={true} value={this.state.telesales_dials} onChange={this.handleInputChange} />
                    <Input id="telesales_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.telesales_mqls} onChange={this.handleInputChange} />
                    <Input id="telesales_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.telesales_appointments} onChange={this.handleInputChange} />
                    <TextArea id="telesales_notes" placeholder="Telesales Notes" placeholderLabel={true} value={this.state.telesales_notes} onChange={this.handleInputChange} />
                </div>

                {/* Linkedin form fields */}
                <div className={["sdr-form", this.props.tab === "linkedin" ? "active" : ""].join(" ")}>
                    <Input id="linkedin_acceptedrequests" placeholder="Requests Accepted" placeholderLabel={true} value={this.state.linkedin_acceptedrequests} onChange={this.handleInputChange} />
                    <Input id="linkedin_engagements" placeholder="Engagements" placeholderLabel={true} value={this.state.linkedin_engagements} onChange={this.handleInputChange} />
                    <Input id="linkedin_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.linkedin_mqls} onChange={this.handleInputChange} />
                    <Input id="linkedin_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.linkedin_appointments} onChange={this.handleInputChange} />
                    <TextArea id="linkedin_notes" placeholder="Linkedin Notes" placeholderLabel={true} value={this.state.linkedin_notes} onChange={this.handleInputChange} />
                </div>

                {/* Email form fields */}
                <div className={["sdr-form", this.props.tab === "email" ? "active" : ""].join(" ")}>
                    <Input id="emails_emailssent" placeholder="Emails Sent" placeholderLabel={true} value={this.state.emails_emailssent} onChange={this.handleInputChange} />
                    <Input id="emails_engagements" placeholder="Engagements" placeholderLabel={true} value={this.state.emails_engagements} onChange={this.handleInputChange} />
                    <Input id="emails_mqls" placeholder="MQLs" placeholderLabel={true} value={this.state.emails_mqls} onChange={this.handleInputChange} />
                    <Input id="emails_appointments" placeholder="Appointments" placeholderLabel={true} value={this.state.emails_appointments} onChange={this.handleInputChange} />
                    <TextArea id="emails_notes" placeholder="Email Notes" placeholderLabel={true} value={this.state.emails_notes} onChange={this.handleInputChange} />
                </div>

                <div className="lead-added-by">
                    <p>
                        Notes added by <span>{this.state.lead_added_by}</span>
                    </p>
                </div>

                {/* Show the save & remove lead buttons for staff */}
                {this.props.staff && (
                    <>
                        <Button onClick={this.saveLead}>Save SDR Notes</Button>
                        <br />
                        <Button onClick={this.removeLead}>Remove SDR Notes</Button>
                    </>
                )}
            </div>
        );
    }
}

export default EditSDR;
