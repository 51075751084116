import React, { Component } from 'react';
import { db, fun } from '../../utils/firebase';
import { lead_types, lead_origin } from '../../utils/selects';
import firebase from "firebase";

/**
 * Components
 */
import Input from '../ui/input/input';
import Select from '../ui/input/select';
import Button from '../ui/button/button';
import { connect } from 'react-redux';

class EditLead extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lead: {},
            staff: false,
            selecting_assign_to: false,
            lead_assigned_to_name: '',
            lead_assigned_to: '',
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        /**
         * Map any props to our state
         */
        this.mapPropsToState();
        /**
         * Event listeners
         */
        document.addEventListener('mousedown', this.clickedTypeSelect);
        document.addEventListener('mousedown', this.clikedOriginSelect);
        document.addEventListener('mousedown', this.clickedOutsideAssignSelect);
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        /**
     * Get lead values from props
         */
        const { lead } = this.props;
        /**
         * If the lead values don't match what we have in state (it's a new lead click)
         */
        if (lead && (lead.id !== this.state.id)) {
            /**
             * Map across the new data to state
             */
            this.mapPropsToState();
        }

        if (this.props.active === false && this.state.lead_assigned_to) {
            // Reset the state
            this.setState({
                lead_assigned_to: '',
                lead_assigned_to_name: '',
                selecting_assign_to: false, // Close the dropdown
            });
        }
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        /**
         * Event listeners
         */
        document.removeEventListener('mousedown', this.clickedTypeSelect);
        document.removeEventListener('mousedown', this.clikedOriginSelect);
        document.removeEventListener('mousedown', this.clickedOutsideAssignSelect);
    }

    /**
     * Map across the prop values to state
     *
     * @function
     */
    mapPropsToState = () => {
        /**
         * Get lead values from props
         */
        const { lead, users } = this.props;
        /**
         * Move prop values into state
         */

        // const userMapping = users.reduce((acc, user) => {
        //     acc[user.id] = user.name; // Key: user ID, Value: user name
        //     return acc;
        // }, {});

        // const leadAssId = lead.lead_assigned_to || lead.saved_by;
        this.setState({ ...lead, }, () => {
            /**
             * Get the name of the user who added the lead
             */
            this.fetchLeadAdder();
        });
    }

    /**
     * Wrapper refs for the lead origin select field
     */
    setWhereRef = node => {
        this.whereRef = node;
    }

    clickedOutsideWhereSelect = (e) => {
        if (this.whereRef && !this.whereRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_where: false
            });
        }
    }

    toggleWhereSelect = () => {
        this.setState({
            selecting_where: !this.state.selecting_where
        });
    }

    setAssignRef = node => {
        this.assignRef = node;
    }

    clickedOutsideAssignSelect = (e) => {
        if (this.assignRef && !this.assignRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_assign_to: false
            });
        }
    }

    toggleAssignToSelect = () => {
        this.setState((prevState) => ({
            selecting_assign_to: !prevState.selecting_assign_to,
        }));
    };
    
    assignToChanged = (value) => {
        console.log(value, "====valuevaluevalue");
        
        this.setState({
            lead_assigned_to: value.value,
            lead_assigned_to_name: value.option,
            selecting_assign_to: false,
        });
    };
    

    /**
     * Wrapper refs for the type select field
     */
    setTypeRef = node => {
        this.typeRef = node;
    }

    clickedOutsideTypeSelect = (e) => {
        if (this.typeRef && !this.typeRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_type: false
            });
        }
    }

    toggleTypeSelect = () => {
        this.setState({
            selecting_type: !this.state.selecting_type
        });
    }

    /**
     * User the UId of the adder to get their name
     *
     * @function
     */
    fetchLeadAdder = () => {
        /**
         * Get the adders ID from state
         */
        const { saved_by, lead_assigned_to } = this.state;
        // const leadAssId = lead_assigned_to;

        // const userMapping = users.reduce((acc, user) => {
        //     acc[user.id] = user.name; // Key: user ID, Value: user name
        //     return acc;
        // }, {});

        /**
         * Get their details from the database
         */
        db.doc(`users/${saved_by}`).get().then((user) => {
            /**
             * Make sure their document exists
             */
            if (user.exists) {
                /**
                 * Update the state with their name or email
                 */
                this.setState({ 
                    lead_added_by: user.data().name || user.data().email, 
                    // lead_assigned_to_name: userMapping[leadAssId] || user.data().name, 
                });
            } else {
                /**
                 * Update the state with a placeholder
                 */
                this.setState({ lead_added_by: '' });
            }
        });
        if(lead_assigned_to && lead_assigned_to?.length > 0){
            db.doc(`users/${lead_assigned_to}`).get().then((user) => {
                /**
                 * Make sure their document exists
                 */
                if (user.exists) {
                    /**
                     * Update the state with their name or email
                     */
                    this.setState({ 
                        lead_assigned_to_name: user.data().name || user.data().email, 
                        // lead_assigned_to_name: userMapping[leadAssId] || user.data().name, 
                    });
                } else {
                    /**
                     * Update the state with a placeholder
                     */
                    this.setState({ lead_assigned_to_name: '' });
                }
            });
        }
    }

    /**
     * Save lead with new data
     *
     * @function
     */
    saveLead = async () => {
        /**
         * Deconstruct the clientID from rops
         */
        const { client } = this.props;
        /**
         * Deconstruct the lead data from state
         */
        const {
            id,
            company,
            contact_email,
            contact_number,
            job_title,
            linkedin,
            // pound_value,
            lead_assigned_to,
            name,
            type,
            website
        } = this.state;
        /**
         * Update the lead document
         */
        await db.doc(`clients/${client}/leads/${id}`).set({
            company: company,
            contact_email: contact_email,
            contact_number: contact_number,
            job_title: job_title,
            linkedin: linkedin,
            // pound_value: pound_value,
            lead_assigned_to: lead_assigned_to,
            name: name,
            type: type,
            website: website,
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
    }

    /**
     * Remove the lead
     *
     * @function
     */
    removeLead = () => {
        /**
         * Deconstruct the clientID from rops
         */
        const { client } = this.props;
        /**
         * Deconstruct the lead ID from state
         */
        const { id } = this.state;
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
        /**
         * Remove the lead document first
         */
        db.doc(`clients/${client}/leads/${id}`).delete();
        /**
         * Remove the lead document and engagements collection through a cloud function
         */
        const recDelete = fun.httpsCallable('recursiveDelete');
        recDelete({ path: `clients/${client}/leads/${id}` }).catch((err) => {
            console.log(err.message);
        });
    }

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    /**
     * Handle the change of the lead type
     *
     * @function
     *
     * @param {Object} value Object containing the readable and variable for the selected item
     */
    leadTypeChanged = (value) => {
        this.setState({
            ...this.state,
            type: value.value,
            selecting_type: false
        });
    }

    /**
     * Handle the change of the lead where
     *
     * @function
     *
     * @param {Object} value Object containing the readable and variable for the selected item
     */
    leadWhereChanged = (value) => {
        this.setState({
            ...this.state,
            where: value.option,
            selecting_where: false
        });
    }


    
    
    render() {
        const userOption = this.props?.users?.reduce((acc, user) => {
            acc[user.name] = user.id;
            return acc;
        }, {});
        // console.log(this.props, "===ascnasjjs:this.props.staff", this.state);
        console.log(this.props.user, "==userOptionuserOption");
        
        return (
            <div className={['modal-form', this.props.active ? 'show' : ''].join(' ')}>
                <Input
                    id="name"
                    placeholder="Person name"
                    readonly={!this.props.staff}
                    value={this.state.name}
                    onChange={this.handleInputChange} />
                <Input
                    id="job_title"
                    placeholder="Job title"
                    readonly={!this.props.staff}
                    value={this.state.job_title}
                    onChange={this.handleInputChange} />
                <Input
                    id="company"
                    placeholder="Company"
                    readonly={!this.props.staff}
                    value={this.state.company}
                    onChange={this.handleInputChange} />
                <Input
                    id="website"
                    placeholder="Website"
                    readonly={!this.props.staff}
                    value={this.state.website}
                    onChange={this.handleInputChange} />
                <Input
                    id="contact_number"
                    placeholder="Phone number"
                    readonly={!this.props.staff}
                    value={this.state.contact_number}
                    onChange={this.handleInputChange} />
                <Input
                    id="contact_email"
                    placeholder="Email address"
                    readonly={!this.props.staff}
                    value={this.state.contact_email}
                    onChange={this.handleInputChange} />
                <Input
                    id="linkedin"
                    placeholder="LinkedIn profile"
                    readonly={!this.props.staff}
                    value={this.state.linkedin}
                    onChange={this.handleInputChange} />
                <div className="inputfield" ref={this.setTypeRef} >
                    <Select
                        placeholder="Lead type"
                        options={lead_types}
                        value={this.state.type && this.state.type.substring(2)}
                        changed={this.leadTypeChanged}
                        toggled={this.state.selecting_type}
                        toggle={this.toggleTypeSelect} />
                </div>
                <div className="inputfield" ref={this.setWhereRef} >
                    <Select
                        placeholder="Lead origin"
                        options={lead_origin}
                        value={this.state.where}
                        changed={this.leadWhereChanged}
                        toggled={this.state.selecting_where}
                        toggle={this.toggleWhereSelect} />
                </div>
                {/* <Input
                    id="pound_value"
                    placeholder="Pound Value"
                    readonly={!this.props.staff}
                    value={this.state.pound_value}
                    onChange={this.handleInputChange} /> */}
                <div className="" ref={this.setAssignRef} >
                    {this.props.user?.is_admin && 
                        <Select
                            placeholder="Lead Assign To"
                            options={userOption}
                            // value={this.state.lead_assigned_to}
                            value={this.state.lead_assigned_to_name}
                            changed={this.assignToChanged}
                            toggled={this.state.selecting_assign_to}
                            toggle={this.toggleAssignToSelect}
                        />}
                </div>
                <div className="lead-added-by">
                    <p>Lead added by <span>{this.state.lead_added_by}</span></p>
                    <p style={{ marginTop: '7px', }}>Lead assign to <span>{this.state.lead_assigned_to_name || this.state.lead_added_by || "None"}</span></p>
                </div>

                {/* Show the save & remove lead buttons for staff */}
                {this.props.staff &&
                    <>
                        <Button onClick={this.saveLead}>Save Lead</Button>
                        <br />
                        <Button onClick={this.removeLead}>Remove Lead</Button>
                    </>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
};

// export default EditLead;
export default connect(mapStateToProps)(EditLead);