export const lead_types = {
    
    'Appointment': '9_Appointment',
    'Appointment - No Show': '9_Appointment-noshow',
    'Hot': 'a_Hot',
    'Moderate': 'b_Moderate',
    'Low': 'c_Low'
}

export const action_types = {
    'Phone call': 'Phone call',
    'Video call': 'Video call',
    'Email': 'Email',
}

export const lead_origin = {
    'LinkedIn': 'LinkedIn',
    'Email Sequencing': 'Email Sequencing',
    'Telesales': 'Telesales',
    'SDR Outreach': 'SDR Outreach',
}

export const report_types = {
    'Monthly Prospecting': 'Monthly Prospecting',
    'Qualified': 'Qualified',
}
