import React from 'react';
import './errors.scss';

const Error403 = () => (
    <div id="error-holder">
        <div className="error-title">
            403
        </div>
        <div className="error-subtitle">
            Not authorised to see this page
        </div>
    </div>
);

export default Error403;