import React from "react";
import { ReactComponent as LoadingIcon } from "../assets/svgs/loading.svg";
import { ReactComponent as CheckIcon } from "../assets/svgs/check.svg";
import { ReactComponent as CaretDownIcon } from "../assets/svgs/caret-down.svg";
import { ReactComponent as CaretUpIcon } from "../assets/svgs/caret-up.svg";
import { ReactComponent as TimesIcon } from "../assets/svgs/times.svg";
import { ReactComponent as InformationIcon } from "../assets/svgs/information.svg";
import { ReactComponent as AlertIcon } from "../assets/svgs/alert.svg";
import { ReactComponent as PinIcon } from "../assets/svgs/pin.svg";
import { ReactComponent as PenIcon } from "../assets/svgs/pen.svg";
import { ReactComponent as CircleIcon } from "../assets/svgs/circle.svg";
import { ReactComponent as UndoIcon } from "../assets/svgs/undo.svg";
import { ReactComponent as SaveIcon } from "../assets/svgs/save.svg";
import { ReactComponent as CommentIcon } from "../assets/svgs/comment.svg";
import { ReactComponent as SignoutIcon } from "../assets/svgs/signout.svg";
import { ReactComponent as ChevronRightIcon } from "../assets/svgs/chevron-right.svg";
import { ReactComponent as TrashIcon } from "../assets/svgs/trash.svg";
import { ReactComponent as ImageIcon } from "../assets/svgs/image.svg";
import { ReactComponent as VideoIcon } from "../assets/svgs/video.svg";
import { ReactComponent as LinkIcon } from "../assets/svgs/link.svg";
import { ReactComponent as DownloadIcon } from "../assets/svgs/download.svg";
import { ReactComponent as CopyIcon } from "../assets/svgs/copy.svg";
import { ReactComponent as PlusIcon } from "../assets/svgs/plus.svg";
import { ReactComponent as UploadIcon } from "../assets/svgs/upload.svg";
// import { ReactComponent as LoadingIcon2 } from "../assets/svgs/loading_n.svg";
import { ReactComponent as EmailSequencing } from "../assets/svgs/Email Sequencing.svg";
import { ReactComponent as LinkedIn } from "../assets/svgs/business.svg";
import { ReactComponent as Mail } from "../assets/svgs/mail.svg";
import { ReactComponent as PhoneCall } from "../assets/svgs/phone-call.svg";
import { ReactComponent as Telesales } from "../assets/svgs/Telesales.svg";
import { ReactComponent as SDROutreach } from "../assets/svgs/SDR Outreach.svg";

const LoadingIconNew = ({ color = "white" }) => (
    <svg className="loading-icon-new" width="40" height="40" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_13_58)">
            <path d="M267.999 119.771C301.072 119.771 327.884 92.9594 327.884 59.8855C327.884 26.8117 301.072 0 267.999 0C234.925 0 208.113 26.8117 208.113 59.8855C208.113 92.9594 234.925 119.771 267.999 119.771Z" fill={color} />
            <path d="M267.998 512C287.841 512 303.926 495.915 303.926 476.072C303.926 456.23 287.841 440.145 267.998 440.145C248.156 440.145 232.071 456.23 232.071 476.072C232.071 495.915 248.156 512 267.998 512Z" fill={color} />
            <path d="M120.85 174.721C150.618 174.721 174.75 150.589 174.75 120.82C174.75 91.0517 150.618 66.9194 120.85 66.9194C91.081 66.9194 66.9487 91.0517 66.9487 120.82C66.9487 150.589 91.081 174.721 120.85 174.721Z" fill={color} />
            <path d="M415.147 445.042C431.684 445.042 445.09 431.636 445.09 415.099C445.09 398.562 431.684 385.156 415.147 385.156C398.61 385.156 385.205 398.562 385.205 415.099C385.205 431.636 398.61 445.042 415.147 445.042Z" fill={color} />
            <path d="M59.8953 315.885C86.348 315.885 107.792 294.441 107.792 267.989C107.792 241.536 86.348 220.092 59.8953 220.092C33.4427 220.092 11.9985 241.536 11.9985 267.989C11.9985 294.441 33.4427 315.885 59.8953 315.885Z" fill={color} />
            <path d="M476.082 291.908C489.303 291.908 500.021 281.19 500.021 267.969C500.021 254.748 489.303 244.031 476.082 244.031C462.861 244.031 452.144 254.748 452.144 267.969C452.144 281.19 462.861 291.908 476.082 291.908Z" fill={color} />
            <path d="M91.2176 385.506C87.3224 389.395 84.2322 394.014 82.1238 399.099C80.0154 404.183 78.9302 409.634 78.9302 415.138C78.9302 420.642 80.0154 426.093 82.1238 431.177C84.2322 436.262 87.3224 440.881 91.2176 444.77C95.1067 448.665 99.7257 451.755 104.81 453.864C109.895 455.972 115.345 457.057 120.849 457.057C126.354 457.057 131.804 455.972 136.889 453.864C141.973 451.755 146.592 448.665 150.481 444.77C154.377 440.881 157.467 436.262 159.575 431.177C161.684 426.093 162.769 420.642 162.769 415.138C162.769 409.634 161.684 404.183 159.575 399.099C157.467 394.014 154.377 389.395 150.481 385.506C146.604 381.59 141.989 378.481 136.903 376.359C131.817 374.238 126.36 373.146 120.849 373.146C115.339 373.146 109.882 374.238 104.796 376.359C99.7101 378.481 95.0949 381.59 91.2176 385.506Z" fill={color} />
            <path d="M415.128 138.774C425.044 138.774 433.082 130.736 433.082 120.82C433.082 110.904 425.044 102.866 415.128 102.866C405.212 102.866 397.174 110.904 397.174 120.82C397.174 130.736 405.212 138.774 415.128 138.774Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_13_58">
                <rect width="512" height="512" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

const GoldIcon = ({ width = 24, height = 24, }) => (
    <svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M155.028 355.114L134.73 356.104L95.376 469.17C94.122 472.771 97.04 476.454 100.833 476.056L155.996 470.28C156.757 470.2 157.526 470.289 158.249 470.541C158.972 470.792 159.63 471.2 160.177 471.735L199.832 510.514C202.558 513.18 207.132 512.105 208.385 508.504L247.996 394.699L236.316 383.363L155.028 355.114Z" fill="#FF4755" />
        <path d="M247.996 394.699L256 371.704L142.71 333.178L134.73 356.105C166.9 379.06 205.852 393.112 247.996 394.699Z" fill="#FC2B3A" />
        <path d="M356.972 355.114L377.27 356.104L416.624 469.17C417.878 472.771 414.96 476.454 411.167 476.056L356.004 470.28C355.243 470.2 354.474 470.289 353.752 470.541C353.029 470.792 352.371 471.2 351.824 471.735L312.169 510.514C309.443 513.18 304.869 512.105 303.616 508.504L264.005 394.699L275.685 383.363L356.972 355.114Z" fill="#FF4755" />
        <path d="M264.004 394.699L256 371.704L369.29 333.178L377.27 356.105C345.1 379.06 306.148 393.112 264.004 394.699Z" fill="#FC2B3A" />
        <path d="M256 360.116C70.148 360.116 70.226 179.587 70.38 176.484C75.259 78.194 156.498 0 256 0C355.502 0 436.741 78.194 441.62 176.485C441.774 179.588 441.852 360.116 256 360.116Z" fill="#FFE27A" />
        <path d="M256 352.969C156.498 352.969 75.262 274.775 70.382 176.484C70.2266 179.604 70.1486 182.727 70.148 185.851C70.148 288.494 153.357 371.703 256 371.703C358.643 371.703 441.852 288.494 441.852 185.851C441.852 182.709 441.772 179.587 441.618 176.484C436.738 274.775 355.502 352.969 256 352.969Z" fill="#F9CF58" />
        <path d="M256 320.126C109.883 320.126 109.687 176.444 109.883 173.346C114.714 96.85 178.286 36.302 256 36.302C333.714 36.302 397.286 96.85 402.117 173.346C402.313 176.443 402.117 320.126 256 320.126Z" fill="#FFBA57" />
        <path d="M256 313.529C178.287 313.529 114.726 252.979 109.895 176.484C109.694 179.602 109.592 182.726 109.588 185.851C109.588 266.712 175.139 332.263 256 332.263C336.861 332.263 402.412 266.712 402.412 185.851C402.412 182.703 402.301 179.582 402.105 176.484C397.274 252.979 333.713 313.529 256 313.529Z" fill="#FFAC3E" />
        <path d="M264.35 272.82C254.185 272.82 245.944 264.58 245.944 254.414V151.263C245.944 151.263 234.931 162.308 221.754 155.681C211.48 150.514 211.48 137.215 211.48 137.215L234.173 98.909C235.802 96.1594 238.119 93.8812 240.895 92.2988C243.672 90.7163 246.813 89.8841 250.009 89.884H264.35C274.515 89.884 282.756 98.124 282.756 108.29V254.414C282.756 264.58 274.515 272.82 264.35 272.82Z" fill="#FFE27A" />
        <path d="M243.415 136.8C238.53 145.047 228.143 148.623 219.48 144.518C216.139 142.948 213.349 140.408 211.474 137.228C206.363 145.874 209.13 156.997 217.64 162.252C226.416 167.672 238.143 164.436 243.399 155.561L245.945 151.262V137.491C245.945 136.109 244.12 135.61 243.415 136.8ZM264.35 263.085C254.185 263.085 245.944 254.845 245.944 244.679V263.414C245.944 273.58 254.185 281.82 264.35 281.82C274.515 281.82 282.756 273.58 282.756 263.414V244.679C282.756 254.845 274.515 263.085 264.35 263.085Z" fill="#F9CF58" />
    </svg>

)

const SilverIcon = ({ width = 24, height = 24, }) => (
    <svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M235.468 382.791L152.938 354.249L134.729 356.104L95.3759 469.17C94.1219 472.771 97.04 476.454 100.833 476.056L155.996 470.28C156.757 470.2 157.526 470.29 158.249 470.541C158.971 470.792 159.63 471.2 160.177 471.735L199.832 510.514C202.558 513.18 207.132 512.105 208.385 508.504L247.996 394.699L235.468 382.791Z" fill="#98DB7C" />
        <path d="M247.996 394.699L256 371.704L142.71 333.178L134.73 356.105C166.9 379.06 205.852 393.112 247.996 394.699Z" fill="#82D361" />
        <path d="M276.532 382.791L359.062 354.249L377.271 356.104L416.625 469.17C417.878 472.771 414.961 476.454 411.168 476.056L356.005 470.28C355.244 470.2 354.475 470.289 353.753 470.541C353.03 470.792 352.372 471.2 351.825 471.735L312.17 510.514C309.444 513.18 304.87 512.105 303.617 508.504L264.006 394.699L276.532 382.791Z" fill="#98DB7C" />
        <path d="M264.004 394.699L256 371.704L369.29 333.178L377.27 356.105C345.1 379.06 306.148 393.112 264.004 394.699Z" fill="#82D361" />
        <path d="M256 359.438C70.38 359.438 70.226 179.588 70.38 176.485C75.259 78.194 156.498 0 256 0C355.436 0 436.633 78.09 441.61 176.288C441.771 179.456 441.61 359.438 256 359.438Z" fill="#E0E0E0" />
        <path d="M256 352.969C156.498 352.969 75.262 274.775 70.382 176.484C70.2265 179.604 70.1485 182.727 70.1479 185.851C70.1479 288.494 153.357 371.703 256 371.703C358.643 371.703 441.852 288.494 441.852 185.851C441.852 182.709 441.772 179.587 441.618 176.484C436.738 274.775 355.502 352.969 256 352.969Z" fill="#CECECE" />
        <path d="M256 320.63C109.588 320.63 109.687 179.582 109.883 176.484C114.714 99.9879 178.286 39.4399 256 39.4399C333.714 39.4399 397.286 99.9879 402.117 176.484C402.313 179.582 402.117 320.63 256 320.63Z" fill="#9E9E9E" />
        <path d="M256 313.529C178.287 313.529 114.726 252.979 109.895 176.485C109.694 179.603 109.592 182.727 109.588 185.852C109.588 266.713 175.139 332.264 256 332.264C336.861 332.264 402.412 266.713 402.412 185.852C402.412 182.704 402.301 179.583 402.105 176.485C397.274 252.979 333.713 313.529 256 313.529Z" fill="#898989" />
        <path d="M298.146 272.82H214.224C204.059 272.82 196.21 255.125 196.975 251.081C199.11 239.783 204.133 229.231 211.556 220.45L270.605 150.662C276.13 144.132 273.665 137.096 272.777 135.094C271.698 132.661 268.343 126.972 260.527 126.708C260.295 126.7 260.063 126.696 259.831 126.696C248.852 126.696 239.921 135.628 239.921 146.607C239.921 156.772 231.68 165.013 221.515 165.013C211.35 165.013 203.109 156.773 203.109 146.607C203.109 115.331 228.554 89.884 259.831 89.884C260.478 89.884 261.122 89.895 261.763 89.917C281.333 90.577 298.445 102.171 306.428 120.172C314.53 138.442 311.571 159.236 298.706 174.44L239.658 244.228C239.441 244.485 239.228 244.745 239.02 245.009H298.147C304.887 245.009 310.781 248.632 313.988 254.037C315.616 256.783 308.311 272.82 298.146 272.82Z" fill="#E0E0E0" />
        <path d="M298.146 263.085H214.224C206.314 263.085 199.589 258.086 196.986 251.083C196.213 255.148 195.822 259.276 195.818 263.414C195.818 273.58 204.059 281.82 214.224 281.82H298.146C308.311 281.82 316.552 273.58 316.552 263.414C316.552 259.989 315.599 256.793 313.97 254.047C310.763 259.451 304.886 263.085 298.146 263.085Z" fill="#CECECE" />
    </svg>

)


const BronzeIcon = ({ width = 24, height = 24, }) => (
    <svg width={width} height={height} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_47_228)">
            <path d="M233.973 381.813L157.227 356.231L134.729 356.105L95.3759 469.17C94.1219 472.771 97.04 476.454 100.833 476.056L155.996 470.28C156.757 470.2 157.526 470.289 158.249 470.541C158.971 470.792 159.63 471.2 160.177 471.735L199.832 510.514C202.558 513.18 207.132 512.105 208.385 508.504L247.996 394.699L233.973 381.813Z" fill="#0083FD" />
            <path d="M247.996 394.699L256 371.704L142.71 333.178L134.73 356.105C166.9 379.06 205.852 393.112 247.996 394.699Z" fill="#0072FC" />
            <path d="M278.027 381.813L354.773 356.231L377.271 356.105L416.625 469.171C417.879 472.772 414.961 476.455 411.168 476.057L356.005 470.281C355.244 470.201 354.475 470.29 353.753 470.542C353.03 470.793 352.372 471.201 351.825 471.736L312.17 510.515C309.444 513.181 304.87 512.106 303.617 508.505L264.006 394.7L278.027 381.813Z" fill="#0083FD" />
            <path d="M264.004 394.699L256 371.704L369.29 333.178L377.27 356.105C345.1 379.06 306.148 393.112 264.004 394.699Z" fill="#0072FC" />
            <path d="M256 356.105C70.38 356.105 70.226 179.588 70.38 176.485C75.259 78.194 156.498 0 256 0C355.502 0 436.741 78.194 441.62 176.485C441.774 179.588 441.62 356.105 256 356.105Z" fill="#FF9838" />
            <path d="M256 352.969C156.498 352.969 75.262 274.775 70.382 176.485C70.2265 179.605 70.1485 182.728 70.1479 185.852C70.1479 288.495 153.357 371.704 256 371.704C358.643 371.704 441.852 288.495 441.852 185.852C441.852 182.711 441.772 179.588 441.618 176.485C436.738 274.775 355.502 352.969 256 352.969Z" fill="#FF8709" />
            <path d="M402.412 185.852C402.412 248.546 336.861 323.264 256 323.264C175.139 323.264 109.588 244.435 109.588 185.852C109.588 182.705 109.687 179.582 109.883 176.484C114.713 99.9879 178.286 39.4399 256 39.4399C333.714 39.4399 397.287 99.9879 402.117 176.484C402.313 179.582 402.412 182.705 402.412 185.852Z" fill="#CE7430" />
            <path d="M256 313.529C178.287 313.529 114.726 252.979 109.895 176.485C109.694 179.603 109.591 182.727 109.588 185.852C109.588 266.713 175.139 332.264 256 332.264C336.861 332.264 402.412 266.713 402.412 185.852C402.412 182.704 402.301 179.583 402.105 176.485C397.274 252.979 333.713 313.529 256 313.529Z" fill="#BC672E" />
            <path d="M256.185 272.82C224.652 272.82 204.588 251.083 200.726 225.293C200.375 222.947 200.264 217.912 200.264 217.912C202.949 211.071 209.611 206.227 217.404 206.227C227.569 206.227 235.81 214.467 235.81 224.633C235.81 235.868 244.95 245.008 256.185 245.008C267.42 245.008 276.561 235.868 276.561 224.633C276.565 221.37 275.78 218.154 274.274 215.259C274.274 215.259 272.892 197.903 257.171 197.903C241.401 197.903 240.345 176.475 240.345 176.475C243.552 171.07 249.446 167.447 256.186 167.447C267.421 167.447 276.562 158.307 276.562 147.072C276.562 135.837 267.421 126.697 256.186 126.697C244.951 126.697 235.811 135.837 235.811 147.072C235.811 157.238 227.57 165.478 217.405 165.478C207.24 165.478 198.999 157.238 198.999 147.072C198.999 115.54 224.653 89.886 256.185 89.886C287.718 89.886 313.372 115.54 313.372 147.072C313.372 162.024 307.606 175.652 298.178 185.853C305.596 193.879 310.747 204.027 312.607 215.272C312.607 215.272 312.664 221.444 312.129 224.587C307.566 251.409 287.718 272.82 256.185 272.82Z" fill="#FFAA5C" />
            <path d="M256.185 185.523C249.445 185.523 243.568 181.889 240.362 176.485C238.672 179.317 237.78 182.554 237.78 185.852C237.78 196.018 246.02 204.258 256.186 204.258C264.043 204.258 270.863 208.736 274.261 215.267C275.772 212.376 276.561 209.162 276.562 205.9C276.561 194.663 267.42 185.523 256.185 185.523ZM256.185 263.085C228.776 263.085 205.818 243.698 200.28 217.919C199.435 220.057 199 222.335 198.998 224.634C198.998 256.167 224.652 281.821 256.185 281.821C287.718 281.821 313.372 256.167 313.372 224.634C313.372 221.445 313.095 218.322 312.591 215.274C308.103 242.36 284.525 263.085 256.185 263.085Z" fill="#EA9957" />
        </g>
        <defs>
            <clipPath id="clip0_47_228">
                <rect width="512" height="512" fill="white" />
            </clipPath>
        </defs>
    </svg>
)


export {
    LoadingIcon,
    CheckIcon,
    CaretDownIcon,
    CaretUpIcon,
    TimesIcon,
    InformationIcon,
    AlertIcon,
    PinIcon,
    PenIcon,
    CircleIcon,
    UndoIcon,
    SaveIcon,
    CommentIcon,
    SignoutIcon,
    ChevronRightIcon,
    TrashIcon,
    ImageIcon,
    VideoIcon,
    LinkIcon,
    DownloadIcon,
    CopyIcon,
    PlusIcon,
    UploadIcon,
    LoadingIconNew,
    GoldIcon,
    SilverIcon,
    BronzeIcon,
    SDROutreach,
    LinkedIn,
    Telesales,
    EmailSequencing,
    PhoneCall,
    Mail,
};
