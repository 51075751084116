import React, { Component } from "react";
import { db, auth } from "../../../utils/firebase";
import EditSDR from "../../forms/edit-sdr";

/**
 * Modal wrapper
 */
import Modal from "../modal";

class ViewSDR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: "summary",
            client: "",
            lead: "",
            staff: false,
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        /**
         * Get the lead ID from props
         */
        const { lead } = this.props;
        /**
         * If it's different to what we've already got
         */
        if (lead && lead.id !== this.state.lead.id) {
            /**
             * Update the state with the lead data
             */
            this.setState({ lead }, () => {
                /**
                 * Then fetch an up to date copy from the database
                 */
                this.fetchLead();
                /**
                 * Check the users permissions for remove/edit
                 */
                this.checkUserStatus();
            });
        }
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Fetch the lead information for updating and populating fields
     *
     * @function
     */
    fetchLead = () => {
        /**
         * Deconstruct the lead data from props
         */
        const { client, lead } = this.props;
        /**
         * Fetch the data from the database
         */
        db.doc(`clients/${client}/leads/${lead}`)
            .get()
            .then((lead) => {
                /**
                 * If the lead data exists
                 */
                if (lead.exists) {
                    /**
                     * Update the state with the data
                     */
                    this._isMounted && this.setState({ lead: { id: lead.id, ...lead.data() } });
                }
            });
    };

    /**
     * Update the state with a lowercase string version of the tab required
     * to switch to it on the UI
     *
     * @function
     *
     * @param {String} tab String value of the tab to switch to
     */
    navigateTab = (tab) => {
        /**
         * Lowercase the tab name and set the state
         */
        this.setState({ active_tab: tab.toLowerCase() });
    };

    /**
     * Check the status of a user for being a staff member
     *
     * @function
     */
    checkUserStatus = () => {
        /**
         * Get the users database record
         */
        db.doc(`users/${auth.currentUser.uid}`)
            .get()
            .then((user) => {
                /**
                 * If the users document exists
                 */
                if (user.exists) {
                    /**
                     * Update the state to whether or not they have staff rights
                     */
                    this.setState({
                        ...this.state,
                        staff: user.data().type === "staff",
                    });
                }
            });
    };

    render() {
        return (
            <Modal active={this.props.active} toggle={this.props.dismiss}>
                <h1>SDR Notes</h1>

                {/* Modal navigation */}
                <div className="modal-nav">
                    <p className={this.state.active_tab === "summary" ? "active" : ""} onClick={() => this.navigateTab("summary")}>
                        Summary
                    </p>
                    <p className={this.state.active_tab === "telesales" ? "active" : ""} onClick={() => this.navigateTab("telesales")}>
                        Telesales
                    </p>
                    <p className={this.state.active_tab === "linkedin" ? "active" : ""} onClick={() => this.navigateTab("linkedin")}>
                        LinkedIn
                    </p>
                    <p className={this.state.active_tab === "email" ? "active" : ""} onClick={() => this.navigateTab("email")}>
                        Email
                    </p>
                </div>

                <EditSDR client={this.props.client} lead={this.state.lead} staff={this.state.staff} dismiss={this.props.dismiss} tab={this.state.active_tab} />
            </Modal>
        );
    }
}

export default ViewSDR;
