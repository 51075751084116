import React, { useEffect, useState } from "react";
import { db } from "../../../utils/firebase";
import firebase from "firebase";
// import moment from "moment";
import "./staff.scss";
import { BronzeIcon, GoldIcon, SilverIcon } from "../../../utils/svgs";



// const ACTIVER_RANK = {
//     0: (<div className="rank-display">1st <GoldIcon /></div>),
//     1: (<div className="rank-display">2nd <SilverIcon /></div>),
//     2: (<div className="rank-display">3rd <BronzeIcon /></div>),
// }
export default function Staff({ staff, data, type, defaultAppointment=50, defaultRevenue=15000, }) {
    const moment = require('moment');
    moment.locale('en-au')
    // const [name, setName] = useState("");
    // const [progress, setProgress] = useState(0);
    const index = data.rank;
    const [kpiTarget, setKpiTarget] = useState(0);
    const [leads, setLeads] = useState([]);
    const [channels, setChannels] = useState({});
    const [leadsLoaded, setLeadsLoaded] = useState(false);
    const [finalObject, setFinalObject] = useState({});
    const { id, name } = staff;
    // let defaultAppointment = 50;
    // let defaultRevenue = 15000;

    // console.log(data, '=--ascacadataatatata');
    
    // const queryss = async (client, savedBy, monthName) => {
    //     try {
    //         const year = moment().year();
    //         const startOfMonth = moment(monthName, "MMMM").startOf("month").year(year).toDate();
    //         const endOfMonth = moment(monthName, "MMMM").endOf("month").year(year).toDate();

    //         const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfMonth);
    //         const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfMonth);

    //         // Build the query
    //         let query = db.collection(`clients/${client}/leads`)
    //             .where("saved_by", "==", savedBy)
    //             // .where("created", ">=", startTimestamp)
    //             // .where("created", "<=", endTimestamp);

    //         // Fetch data
    //         const querySnapshot = await query.get();

    //         if (!querySnapshot.empty) {
    //             const leads = querySnapshot.docs.map((doc) => ({
    //                 id: doc.id,
    //                 ...doc.data(),
    //             }));
    //             console.log(leads, "final9090999===leaddssssss");
    //             return leads;
    //         } else {
    //             console.log("No leads found for the selected month");
    //             return [];
    //         }
    //     } catch (error) {
    //         console.error("Error in queryss:", error);
    //         return [];
    //     }
    // };

    // const fetchStaffLeads = async () => {
    //     if (clients.length > 0 && id) {
    //         const allDataObj = {};
    //         // const staffId = staffList[0].id;
    //         const allProm = clients.map((client) => queryss(client.id, id));
    //         const res = await Promise.all(allProm);
    //         // allDataObj[id] = {};
    //         res.forEach(eachArr => {
    //             if(eachArr.length > 0){
    //                 allDataObj["totalAppointments"] = (allDataObj["totalAppointments"] || 0) + eachArr.length;
    //                 eachArr.forEach(lead => {
    //                     allDataObj[lead.where] = (allDataObj[lead.where] || 0) + 1;
    //                 });
    //             }
    //         });
    //         setFinalObject(allDataObj);
    //         setProgress(allDataObj["totalAppointments"] / defaultAppointment * 100);
    //         console.log(res, "=ascancjasn:ressssss");
    //     }
    // };

    // const getAllLeads = () => {
    //     const fianlData = [];
    //     const startOfMonth = moment(monthName, "MMMM").startOf("month").year(year).toDate();
    //     const endOfMonth = moment(monthName, "MMMM").endOf("month").year(year).toDate();

    //     const startTimestamp = firebase.firestore.Timestamp.fromDate(startOfMonth);
    //     const endTimestamp = firebase.firestore.Timestamp.fromDate(endOfMonth);

    //     // 
    //     const leadsRef = firebase.firestore().collectionGroup('leads');
    //     leadsRef.get()
    //     .then((snapshot) => {
    //         snapshot.forEach((doc) => {
    //             // console.log(doc.id, " => ", doc.data());
    //             fianlData.push({ id: doc.id, ...doc.data(), });
    //         });
    //         console.log(fianlData, '====fianlDatafianlData');
    //     })
    //     .catch((error) => {
    //         console.error("Error retrieving leads: ", error);
    //     });
    // }



    // useEffect(() => {
    //     // fetchStaffLeads();
    //     // getAllLeads();
    // }, [clients, id]);

    const formatRevenue = (revenue) => {
        if (revenue < 1000) {
            return revenue.toString();
        }
        const formatted = (revenue / 1000).toFixed(1); // Format with one decimal
        return formatted.endsWith(".0") 
            ? `${Math.floor(revenue / 1000)}k` // Remove ".0" for whole numbers
            : `${formatted}k`; // Keep decimal for non-whole numbers
    };

    const sortLeadOriginOnHover = (data) => {
        return Object.entries(data)
            .sort(([keyA], [keyB]) => {
                const order = ["LinkedIn", "Email Sequencing", "Telesales", "SDR Outreach"];
                const indexA = order.indexOf(keyA);
                const indexB = order.indexOf(keyB);
                return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
            })
            ?.map(([key, value]) => {
                if (key !== 'totalAppointments' && type === 'By Total Appointments') return <p key={key}>{key.length==0 ? "No Lead Origin Selected": key}: {value}</p>;
                if (key !== 'totalAppointments') return <p key={key}>{key.length==0 ? "No Lead Origin Selected": key}: £{value}</p>;
                return null;
            });
    }

    const getNameAndRanked = (name, index, progress) => {
        // console.log(progress, "==cancsnprogress");
        const ACTIVER_RANK_BADGE = {
            1: (<span className="rank-display-icon"><GoldIcon /></span>),
            2: (<span className="rank-display-icon"><SilverIcon /></span>),
            3: (<span className="rank-display-icon"><BronzeIcon /></span>),
        }
        const ACTIVER_RANK = {
            1: (<span className="rank-display">1st</span>),
            2: (<span className="rank-display">2nd</span>),
            3: (<span className="rank-display">3rd</span>),
        }
        return(
            <>
                <p className="client-rank text-color-orange">{progress > 0 ? (ACTIVER_RANK[index] || `${index}th`) : <></>}</p>
                <p className="client-name">{name}</p>
                <p className="client-rank">{progress > 0 ? (ACTIVER_RANK_BADGE[index] || ``): ''}</p>
            </>
        )
    }

    // if((!data?.progressByRev && type === 'By Monetary Value') || (type === 'By Total Appointments' && !data?.progressByApmnt)) return null;
    

    if (type === 'By Total Appointments') {
        const progress = data?.progressByApmnt || 0;
        // data["totalAppointments"] / defaultAppointment * 100;
        
        return (
            <div className="client-row" id={id}>
                {/* {getNameAndRanked(name, index)} */}
                {progress > 0 ? getNameAndRanked(name, index, progress): <p className="client-name">{name}</p>}
                {/* <p className="client-name">{name}</p>
                <p className="client-rank">{ACTIVER_RANK[index] || `${index+1}th`}</p> */}
                <div className="kpi-progress">
                    {/* The KPI breakdown is shown on hover */}
                    <div className="lead-breakdown">
                        {sortLeadOriginOnHover(data.appointment)}
                    </div>
                    {(progress > 0) &&
                        <div className={[
                            "kpi-progress-bar",
                            progress >= 0 && progress <= 49 && "red",
                            progress >= 50 && progress <= 79 && "amber",
                            progress >= 80 && "green",
                        ].join(" ")}
                            style={{
                                width: (progress >= 100) ? "100%" : `${progress}%`,
                                overflow: "visible", // Ensure content is visible
                                position: "relative" // Required for absolute positioning of text
                            }}>
                            <p style={{
                                whiteSpace: "nowrap", // Prevent text wrapping
                                position: "absolute", // Make text independent of container width
                                left: "20%", // Align text to the right outside the progress bar
                                // transform: "translateX(10px)", // Add spacing
                            }}>
                                {data.totalAppointments} Appointments ({progress?.toFixed(2)}%)
                            </p>
                        </div>
                    }



                    {/* If there is no progress on this client yet */}
                    {(progress === 0) && 
                        <span className="no-kpi-value">Yet to register first appointment</span>
                    }

                    {/* kpiTarget target */}
                    <span className="kpi-target">{defaultAppointment}</span>
                </div>
            </div>
        );
    }

    const progress = data?.progressByRev; 
    // data["totalRevenue"] / defaultRevenue * 100;
    return (
        <div className="client-row" id={id}>
            {getNameAndRanked(name, index, progress)}
            {/* <p className="client-name">{name}</p>
            <p className="client-rank">{ACTIVER_RANK[index] || `${index+1}th`}</p> */}
            <div className="kpi-progress">
                {/* The KPI breakdown is shown on hover */}
                <div className="lead-breakdown">
                    {sortLeadOriginOnHover(data.monetary)}
                </div>

                {/* Is there a progress present in state */}
                {(progress > 0) &&
                    <div className={[
                        "kpi-progress-bar",
                        progress >= 0 && progress < 50 && "red",
                        progress >= 50 && progress < 80 && "amber",
                        progress >= 80 && "green",
                    ].join(" ")}
                        style={{ width: (progress >= 100) ? "100%" : `${progress}%` }}>
                        <p>
                            £{data["totalRevenue"]} ({progress?.toFixed(2)}%)
                        </p>
                    </div>
                }

                {/* If there is no progress on this client yet */}
                {(progress === 0) &&
                    <span className="no-kpi-value">Please Add Pound Value in Client</span>
                }

                {/* kpiTarget target */}
                <span className="kpi-target">{formatRevenue(defaultRevenue)}</span>
            </div>
        </div>
    );
}