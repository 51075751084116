import React from 'react'
import './LeadsCount.scss';

const LeadsCount = ({ data=[], }) => {

    if(!Array.isArray(data)) return null;

    const typeCount = data.reduce((acc, item) => {
        acc[item?.type] = (acc[item?.type] || 0) + 1;
        return acc;
    }, {});
    
  return (
    <div className='leads-count-container'>
        {Object.keys(typeCount).map((key, ind) => (
            <React.Fragment key={ind}>
                <div className={["type-indicator", key?.substring(2)].join(" ")} />
                <span className='count'>({typeCount[key]})</span>
            </React.Fragment>
        ))}
    </div>
  )
}

export default LeadsCount