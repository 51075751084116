import React, { useEffect, useState } from 'react'
import { db } from '../../../../utils/firebase';
import { useSelector } from 'react-redux';
import { CSVLink, CSVDownload } from "react-csv";
import { LoadingIconNew } from '../../../../utils/svgs';

const GenerateCSV = ({ children, client, filename="", data=[], className="" }) => {
    // const users = useSelector(state => state?.user?.myUser)
    const users = useSelector(state => state?.user?.allUsers); // allUsers
    const [csv, setCsv] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    console.log(filename, " ---=--- ", data, "==canscjansjacnsasncteststtst, ", users);
    

    const fetchEngagements = (client, leadId) => {
        return new Promise((resolve, reject) => {
            db.collection(`clients/${client}/leads/${leadId}/engagements`).onSnapshot((engagementsSnap) => {
                let engagements = [];
                try {
                    engagementsSnap.docChanges().forEach((change) => {
                        if (change.type === 'added') {
                            engagements.push({ id: change.doc.id, ...change.doc.data() });
                        }
                        if (change.type === 'modified') {
                            engagements = engagements.filter(e => e.id !== change.doc.id);
                            engagements.push({ id: change.doc.id, ...change.doc.data() });
                        }
                        if (change.type === 'removed') {
                            engagements = engagements.filter(e => e.id !== change.doc.id);
                        }
                    });
                    engagements.sort((a, b) => new Date(a.created.seconds) - new Date(b.created.seconds));
                    resolve(engagements);
                } catch (error) {
                    reject(error);
                }
            });
        });
    };

    // const changeCSVdata = async (leadsData) => {
    //     // console.log(leadsData, "====leadsData====");
    //     setIsLoading(true);
    //     let newLeads = [];
    //     for (const [index, lead] of leadsData.entries()) {
    //         let usrMatch;
    //         for (let j = 0; j < users.length; j++) {
    //             if (users[j].id === lead.saved_by) {
    //                 usrMatch = users[j];
    //                 break;
    //             }
    //         }
    //         if (usrMatch !== undefined) {
    //             // Wait for engagements to be fetched
    //             const engagements = await fetchEngagements(client, lead.id);
    //             // console.log(engagements, "Fetched engagements for lead:", lead);
    
    //             let newLead = { ...lead };
    //             newLead.saved_by = usrMatch.name;
    //             delete newLead.init_engagement;
    //             newLead.id = index + 1;
    
    //             if (newLead.hasOwnProperty('created')) {
    //                 let d = new Date(0);
    //                 d.setUTCSeconds(newLead.created);
    //                 newLead.created = d;
    //             }
    //             if (newLead.hasOwnProperty('updated')) {
    //                 let d = new Date(0);
    //                 d.setUTCSeconds(newLead.updated);
    //                 newLead.updated = d;
    //             }
    
    //             // Add engagement messages to the lead object dynamically
    //             const engm = {}
    //             engagements.forEach((engagement, i) => {
    //                 engm[`init_message_${i + 1}`] = engagement.message;
    //             });
                

    //             newLeads.push({
    //                 id: newLead.id,
    //                 type: newLead.type,
    //                 name: newLead.name,
    //                 company: newLead.company,
    //                 contact_email: newLead.contact_email,
    //                 contact_number: newLead.contact_number,
    //                 website: newLead.website,
    //                 linkedin: newLead.linkedin,
    //                 where: newLead.where,
    //                 saved_by: newLead.saved_by,
    //                 assigned_to: '',
    //                 job_title: newLead.job_title,
    //                 created: newLead.created,
    //                 updated: newLead.updated,
    //                 ...engm,
    //             });
    //         }
    //     }
    //     // return newLeads;
    //     setCsv(newLeads);
    //     setIsLoading(false);
    // };

    const changeCSVdata = async (leadsData) => {
        setIsLoading(true);
        let newLeads = [];
        
        // Fetch user matches and engagements for each lead
        for (const [index, lead] of leadsData.entries()) {
            let usrMatch, leadAssignedUser;
            for (let j = 0; j < users.length; j++) {
                if (users[j].id === lead.saved_by) {
                    usrMatch = users[j];
                }
                if (users[j].id === lead.lead_assigned_to) {
                    leadAssignedUser = users[j];
                }
            }
    
            if (usrMatch !== undefined) {
                // Fetch engagements with error handling
                let engagements = [];
                try {
                    engagements = await fetchEngagements(client, lead.id);
                } catch (error) {
                    console.error("Error fetching engagements:", error);
                }
    
                let newLead = { ...lead };
                newLead.saved_by = usrMatch.name;
                delete newLead.init_engagement;
    
                // Convert Firestore timestamps to Date strings (ISO format)
                if (newLead?.created && newLead?.created?.seconds) {
                    let createdDate = new Date(newLead.created.seconds * 1000); // Convert to milliseconds
                    newLead.created = createdDate.toISOString(); // Convert to readable format
                }
                if (newLead?.updated && newLead?.updated?.seconds) {
                    let updatedDate = new Date(newLead.updated.seconds * 1000); // Convert to milliseconds
                    newLead.updated = updatedDate.toISOString(); // Convert to readable format
                }
    
                // Add engagement messages with proper escaping
                const engm = {};
                engagements.forEach((engagement, i) => {
                    engm[`init_message_${i + 1}`] = engagement.message ? engagement?.message?.replace(/[\r\n]+/g, ' ')?.replace(/"/g, '""') : "";
                });
    
                newLeads.push({
                    id: newLead.id,
                    type: newLead.type,
                    name: newLead.name,
                    company: newLead.company,
                    contact_email: newLead.contact_email,
                    contact_number: newLead.contact_number,
                    website: newLead.website,
                    linkedin: newLead.linkedin,
                    where: newLead.where,
                    saved_by: newLead.saved_by,
                    assigned_to: '',
                    job_title: newLead.job_title,
                    created: newLead.created,
                    updated: newLead.updated,
                    ...engm,
                });
            }
        }
    
        // Sort the leads by 'updated' date in descending order
        newLeads.sort((a, b) => {
            if (a.updated && b.updated) {
                return new Date(b.updated) - new Date(a.updated); // Sort in descending order
            }
            return 0; // If no updated field, keep original order
        });
    
        // Group leads by month & year from 'updated' field
        const groupedByMonth = newLeads.reduce((groups, lead) => {
            const updatedDate = new Date(lead.updated);
            const monthKey = `${updatedDate.getFullYear()}-${updatedDate.getMonth() + 1}`; // Format as "YYYY-MM"
            
            if (!groups[monthKey]) {
                groups[monthKey] = [];
            }
            
            groups[monthKey].push(lead);
            return groups;
        }, {});
    
        // Now for each month group, sort by 'type'
        let sortedLeads = [];
        Object.keys(groupedByMonth).forEach(monthKey => {
            const monthLeads = groupedByMonth[monthKey];
            monthLeads.sort((a, b) => a.type.localeCompare(b.type)); // Sort by type
            sortedLeads = sortedLeads.concat(monthLeads);
        });
    
        // After sorting by month and type, regenerate 'id' based on the final order
        sortedLeads = sortedLeads.map((lead, index) => ({
            ...lead,
            id: index + 1, // Regenerate the id sequentially based on the sorted order
        }));
    
        setCsv(sortedLeads);
        setIsLoading(false);
    };
    

    // working fine sort by updated
    // const changeCSVdata = async (leadsData) => {
    //     // console.log(leadsData, "==casknscasn:leadsData ", filename);
        
    //     setIsLoading(true);
    //     let newLeads = [];
    //     for (const [index, lead] of leadsData.entries()) {
    //         let usrMatch, leadAssignedUser;
    //         for (let j = 0; j < users.length; j++) {
    //             if (users[j].id === lead.saved_by) {
    //                 usrMatch = users[j];
    //                 // break;
    //             }
    //             if (users[j].id === lead.lead_assigned_to) {
    //                 leadAssignedUser = users[j];
    //             }
    //         }
    //         // console.log(usrMatch, " == ,", filename, "--cnacnasncscjn0099ascnjas");
            
    //         if (usrMatch !== undefined) {
    //             // Fetch engagements with error handling
    //             let engagements = [];
    //             try {
    //                 engagements = await fetchEngagements(client, lead.id);
    //             } catch (error) {
    //                 console.error("Error fetching engagements:", error);
    //             }
    
    //             let newLead = { ...lead };
    //             newLead.saved_by = usrMatch.name;
    //             delete newLead.init_engagement;
    //             newLead.id = index + 1;
    
    //             // // Format dates properly
    //             // if (newLead.created) {
    //             //     let d = new Date(0);
    //             //     d.setUTCSeconds(newLead.created);
    //             //     newLead.created = d.toISOString(); 
    //             // }
    //             // if (newLead.updated) {
    //             //     let d = new Date(0);
    //             //     d.setUTCSeconds(newLead.updated);
    //             //     newLead.updated = d.toISOString();
    //             // }

    //             if (newLead?.created && newLead?.created?.seconds) {
    //                 let createdDate = new Date(newLead.created.seconds * 1000); // Convert to milliseconds
    //                 newLead.created = createdDate.toISOString(); // Convert to readable format
    //             }
    //             if (newLead?.updated && newLead?.updated?.seconds) {
    //                 let updatedDate = new Date(newLead.updated.seconds * 1000); // Convert to milliseconds
    //                 newLead.updated = updatedDate.toISOString(); // Convert to readable format
    //             }
                
                
    
    //             // Add engagement messages with proper escaping
    //             const engm = {};
    //             engagements.forEach((engagement, i) => {
    //                 engm[`init_message_${i + 1}`] = engagement.message ? engagement?.message?.replace(/[\r\n]+/g, ' ')?.replace(/"/g, '""') : "";
    //             });
    
    //             newLeads.push({
    //                 id: newLead.id,
    //                 type: newLead.type || "",
    //                 name: newLead.name || "",
    //                 job_title: newLead.job_title || "",
    //                 company: newLead.company || "",
    //                 contact_email: newLead.contact_email || "",
    //                 contact_number: newLead.contact_number || "",
    //                 website: newLead.website || "",
    //                 linkedin: newLead.linkedin || "",
    //                 where: newLead.where || "",
    //                 saved_by: newLead.saved_by || "",
    //                 assigned_to: leadAssignedUser?.name || "",
    //                 job_title: newLead.job_title || "",
    //                 created: newLead.created || "",
    //                 updated: newLead.updated || "",
    //                 ...engm,
    //             });
    //         }
    //     }

    //     // console.log(newLeads, "==casknscasn:leadsData2, ", filename);

    //     newLeads.sort((a, b) => {
    //         if (a.updated && b.updated) {
    //             return new Date(b.updated) - new Date(a.updated); // Sort in descending order
    //         }
    //         return 0; // If no updated field, keep original order
    //     });

    //     newLeads = newLeads.map((lead, index) => ({
    //         ...lead,
    //         id: index + 1,
    //     }));
    
    //     setCsv(newLeads);
    //     setIsLoading(false);
    // };
    

    useEffect(()=>{
        changeCSVdata(data);
    }, [data]);

    if(isLoading) return <div className='flex'><LoadingIconNew width='30' height='30' /></div>

  return (
    <CSVLink className={className} filename={filename} data={csv}>
        {children}
    </CSVLink>
  )
}

export default GenerateCSV