import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Select = (props) => (
    <div className="ib-check">
        <div className="ib-check-wrap">
            <input type="checkbox" id={props.id} name={props.id} value="true" onClick={props.toggle} checked={props.checked} />
            <div className="checkbox">
                <FontAwesomeIcon icon={faCheck} />
            </div>
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    </div>
);

export default Select;
