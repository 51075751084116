import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const Select = (props) => (
    <div className="ib-select">
        {props.label ? <label>{props.label}</label> : null}
        <div className="ib-select-wrap">
            <div className="ib-select-input" onClick={() => props.toggle()}>
                <p className={[props.value ? 'chosen' : '']}>
                    {props.value ? props.value : props.placeholder}
                </p>
                <FontAwesomeIcon icon={props.toggled ? faCaretUp : faCaretDown} />
            </div>
            <div className={['ib-select-list', props.toggled ? 'show' : ''].join(' ')}>
                {Object.entries(props.options).map(([option, value]) => (
                    <div key={option} className="ib-select-option" onClick={() => props.changed({ option, value })}>{option}</div>
                ))}
            </div>
        </div>
    </div>
);

export default Select;