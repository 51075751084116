import React from "react";

/**
 * Styles
 */
import "./input.scss";

const Input = (props) => (
    <div className={["inputfield", props.class].join(" ")}>
        <input
            name={props.id}
            id={props.id}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onInput={props.onInput}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readonly}
            autoComplete="off"
            className={[(props.value?.length > 0) && "has-value"]}
        />
        {props.placeholderLabel && <label>{props.placeholder}</label>}
        {props.error && <small>{props.error}</small>}
    </div>
);

export default Input;
