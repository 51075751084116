import React from 'react';






const ViewFilter =(props)=>{
    return(
        <div>
            <h1>Hello</h1>
        </div>
    )
}

export default ViewFilter