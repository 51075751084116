import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faSlidersH,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import "../navigation.scss";
import Sdr_dash_icon from "../sdr_dash_icon";

const Navigation = (props) => (
  <>
    <div className="nav-item" onClick={() => props.navigate("clients")}>
      <FontAwesomeIcon icon={faUsers} />
    </div>
    
    <div className="nav-item" onClick={() => props.navigate("appointments")}>
      <FontAwesomeIcon icon={faSlidersH} />
    </div>
    <div className="nav-item" onClick={() => props.navigate("reports")}>
      <FontAwesomeIcon icon={faChartPie} />
    </div>
    <div className="nav-item" onClick={() => props.navigate("sdr-dashboard")}>
      <Sdr_dash_icon />
    </div>
  </>
);

export default Navigation;
