import React, { useContext } from "react";
import { OnboardingContext } from "../../../utils/providers/onboarding";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/input/textarea";

/**
 * Functional component returning a stage of fields for the onboarding process
 */
function Competitors() {
    /**
     * Deconstruct the data needed from the onboarding context
     */
    const {
        stage, setStage,
        competitors, setCompetitors,
    } = useContext(OnboardingContext);

    return (
        <div className="onboarding-stage-window">
            <h1>Competitors</h1>

            <div className="ob-input-block">
                <Textarea
                    type="text"
                    placeholder="Please list 3 relevant competitors"
                    value={competitors.relevant_competitors}
                    onChange={(e) => setCompetitors({
                        ...competitors,
                        relevant_competitors: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="For each of these 3, why are you different? Pricing comparison?"
                    value={competitors.why_different}
                    onChange={(e) => setCompetitors({
                        ...competitors,
                        why_different: e.target.value,
                    })} />
            </div>

            <Button onClick={() => setStage(stage + 1)}>
                Continue
            </Button>
        </div>
    )
}

export default Competitors;