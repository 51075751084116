import React, { useContext } from "react";
import { OnboardingContext } from "../../../utils/providers/onboarding";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/input/textarea";

/**
 * Functional component returning a stage of fields for the onboarding process
 */
function Company() {
    /**
     * Deconstruct the data needed from the onboarding context
     */
    const {
        stage, setStage,
        company, setCompany,
    } = useContext(OnboardingContext);

    return (
        <div className="onboarding-stage-window">
            <h1>Company Overview, values &amp; culture</h1>

            <div className="ob-input-block cols-2">
                <Textarea
                    type="text"
                    placeholder="Introduction and history"
                    class="span-2"
                    value={company.introduction}
                    onChange={(e) => setCompany({
                        ...company,
                        introduction: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Please list your values as a business"
                    class="span-2"
                    value={company.values}
                    onChange={(e) => setCompany({
                        ...company,
                        values: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="What does your company culture look like?"
                    class="span-2"
                    value={company.culture}
                    onChange={(e) => setCompany({
                        ...company,
                        culture: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="What are your key objectives as a business for the year ahead?"
                    class="span-2"
                    value={company.key_objectives}
                    onChange={(e) => setCompany({
                        ...company,
                        key_objectives: e.target.value,
                    })} />
            </div>

            <Button onClick={() => setStage(stage + 1)}>
                Continue
            </Button>
        </div>
    )
}

export default Company;