import React, { Component } from 'react';

/**
 * Components
 */
import AddLead from '../../forms/add-lead';

/**
 * Modal wrapper
 */
import Modal from '../modal';

class AddLeadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // console.log(this.props.active,"ACTIVE FROM CHILD")
        return (
            <Modal active={this.props.active}  toggle={this.props.dismiss}>
                <h1>Record Lead</h1>

                {/* Add lead form */}
                <AddLead
                    active={this.props.active}
                    clientPoundValue={this.props.clientPoundValue || 0}
                    client={this.props.client}
                    dismiss={this.props.dismiss} />
            </Modal>
        );
    }
}

export default AddLeadModal;