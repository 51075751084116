import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "../containers/login/login";
import Clients from "../containers/clients/clients";
import Appointments from "../containers/appointments/appointments";
import Reports from "../containers/reports/reports";
import Settings from "../containers/settings/settings";
import Error404 from "../containers/errors/404.js";
import Error403 from "../containers/errors/403.js";
import Onboarding from "../containers/onboarding/onboarding";

import { OnboardingProvider } from "../utils/providers/onboarding";
import SdrDashboard from "../containers/sdr_dashboard/sdr_dashboard.js";

class AppRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }

    render() {
        if (!this.props.loggedin) {
            return (
                <Switch>
                    {/* Login */}
                    <Route path="*" component={Login} />
                </Switch>
            );
        } else {
            return (
                <Switch>
                    {/* Login */}
                    <Route path="/login" exact component={Login} />
                    <Route path="/clients" exact component={Clients} />
                    <Route path="/appointments" exact component={Appointments} />
                    <Route path="/sdr-dashboard" exact component={SdrDashboard} />

                    {this.props.user?.is_admin &&
                        <>
                            <Route path="/reports" exact component={Reports} />
                            <Route path="/settings" exact component={Settings} />
                        </>
                    }

                    <OnboardingProvider>
                        <Route path="/onboarding/:id" exact component={Onboarding} />
                    </OnboardingProvider>

                    {/* Redirect */}
                    <Redirect from="/" to="/login" exact />

                    {/* Error pages */}
                    <Route path="/unauthorised" exact component={Error403} />
                    <Route path="*" component={Error404} />
                </Switch>
            );
        }
    }
}

export default AppRouter;
