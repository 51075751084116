import React, { useContext } from "react";
import { OnboardingContext } from "../../../utils/providers/onboarding";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Input from "../../../components/ui/input/input";
import Textarea from "../../../components/ui/input/textarea";

/**
 * Functional component returning a stage of fields for the onboarding process
 */
function Stakeholders() {
    /**
     * Deconstruct the data needed from the onboarding context
     */
    const {
        stage,
        setStage,
        stakeholder,
        setStakeholder,
    } = useContext(OnboardingContext);

    return (
        <div className="onboarding-stage-window">
            <h1>Stakeholders involved in the project, why Curral?</h1>

            <div className="ob-input-block cols-2">
                <Input
                    type="text"
                    placeholder="Full name"
                    value={stakeholder.name}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        name: e.target.value,
                    })} />

                <Input
                    type="text"
                    placeholder="Job title"
                    value={stakeholder.job_title}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        job_title: e.target.value,
                    })} />

                <Input
                    type="email"
                    placeholder="Email"
                    value={stakeholder.email}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        email: e.target.value,
                    })} />

                <Input
                    type="tel"
                    placeholder="Phone"
                    value={stakeholder.phone}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        phone: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Role within project"
                    class="span-2"
                    value={stakeholder.role}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        role: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Why are you working with Curral?"
                    class="span-2"
                    value={stakeholder.why}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        why: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Are there any internal challenges as a business you're facing with business development?"
                    class="span-2"
                    value={stakeholder.internal_challenges}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        internal_challenges: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="If you're launching a new offering or entering a new market, what's the reason for doing so?"
                    class="span-2"
                    value={stakeholder.new_offerings}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        new_offerings: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="What are your expectations of Curral?"
                    class="span-2"
                    value={stakeholder.expectations}
                    onChange={(e) => setStakeholder({
                        ...stakeholder,
                        expectations: e.target.value,
                    })} />
            </div>

            <Button onClick={() => setStage(stage + 1)}>
                Continue
            </Button>
        </div>
    )
}

export default Stakeholders;