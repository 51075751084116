import React, { Component } from "react";
import firebase from "firebase";
import { db } from "../../utils/firebase";

/**
 * Components
 */
import Input from "../ui/input/input";
import Button from "../ui/button/button";
import Checkbox from "../ui/input/checkbox";
import { connect } from "react-redux";

class EditClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tracker: false,
            archived: false,
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        /**
         * Map any props to our state
         */
        this.mapPropsToState();
    }

    /**
     * Component update
     *
     * @function
     */

    componentDidUpdate(prevProps) {
        const { client } = this.props;
    
        // Check if the client has changed (compare IDs or other unique properties)
        if (client && client.id !== prevProps.client?.id) {
            this.mapPropsToState();
        }
    }
    // componentDidUpdate() {
    //     /**
    //      * Get client values from props
    //      */
    //     const { client } = this.props;
    //     /**
    //      * If the client values don't match what we have in state
    //      */
    //     if (client && client.id !== this.state.id) {
    //         /**
    //          * Map across the new data to state
    //          */
    //         this.mapPropsToState();
    //     }
    // }

    /**
     * Map across the prop values to state
     *
     * @function
     */
    mapPropsToState = () => {
        /**
         * Get client values from props
         */
        const { client } = this.props;
        // console.log(client, "===clienntttttttt");
        
        /**
         * Move prop values into state
         */
        this.setState({ ...client });
    };

    /**
     * Save client with new data
     *
     * @function
     */
    saveClient = () => {
        /**
         * Deconstruct the clientID from rops
         */
        const { client } = this.props;
        /**
         * Deconstruct the client data from state
         */
        const { name, tracker, kpi_target, archived, pound_value, } = this.state;
        /**
         * Update the lead document
         */
        const dataToBeSend = {
            archived,
            name: name,
            tracker: tracker,
            kpi_target: kpi_target || 0,
            // pound_value,
            updated: firebase.firestore.FieldValue.serverTimestamp(),
        };
        if(pound_value) dataToBeSend["pound_value"] = pound_value;

        db.doc(`clients/${client.id}`).set(
            dataToBeSend,
            { merge: true }
        );
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
    };

    /**
     * Remove the client
     *
     * @function
     */
    removeClient = () => {
        /**
         * Deconstruct the clientID from rops
         */
        const { client } = this.props;
        /**
         * Update the lead document
         */
        db.doc(`clients/${client.id}`).delete();
        /**
         * Dismiss the edit modal
         */
        this.props.dismiss();
    };

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };

    /**
     * Handler for the tracker checkbox
     *
     * @function
     */
    handleTrackerToggle = (e) => {
        this.setState({
            ...this.state,
            tracker: !this.state.tracker,
        });
    };

    handleArchivedToggle = (e) => {
        this.setState({
            ...this.state,
            archived: !this.state.archived,
        });
    };

    
    
    render() {
        // console.log(this.state, "==caojscascas:aasc");
        return (
            <div className={["modal-form", this.props.active ? "show" : ""].join(" ")}>
                <Input
                    id="name"
                    placeholder="Client name"
                    value={this.state.name}
                    onChange={this.handleInputChange} />

                <Checkbox
                    id="tracker"
                    label="Show on the Appointments Tracker"
                    toggle={this.handleTrackerToggle}
                    checked={this.state.tracker} />

                {this.state.tracker &&
                    <Input
                        id="kpi_target"
                        placeholder="Monthly KPI target"
                        value={this.state.kpi_target}
                        onChange={this.handleInputChange} />
                }

                {/* {this.props.user?.is_admin &&  */}
                <Input
                    id="pound_value"
                    name="pound_value"
                    placeholder="Pound Value"
                    value={this.state.pound_value}
                    onChange={this.handleInputChange} 
                />
                {/* } */}

                <Checkbox
                    id="archived"
                    label="Archive this client"
                    toggle={this.handleArchivedToggle}
                    checked={this.state.archived} />

                <Button onClick={this.saveClient}>Save Client</Button>
                <br />
                <Button onClick={this.removeClient}>Delete Client</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
};

// export default EditLead;
export default connect(mapStateToProps)(EditClient);