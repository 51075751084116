import React, { Component } from "react";

/**
 * Components
 */
import AddSDR from "../../forms/add-sdr";

/**
 * Modal wrapper
 */
import Modal from "../modal";

class AddSDRModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: "summary",
        };
    }

    /**
     * Update the state with a lowercase string version of the tab required
     * to switch to it on the UI
     *
     * @function
     *
     * @param {String} tab String value of the tab to switch to
     */
    navigateTab = (tab) => {
        /**
         * Lowercase the tab name and set the state
         */
        this.setState({ active_tab: tab.toLowerCase() });
    };

    render() {
        return (
            <Modal active={this.props.active} toggle={this.props.dismiss}>
                <h1>New SDR Note</h1>

                {/* Modal navigation */}
                <div className="modal-nav">
                    <p className={this.state.active_tab === "summary" ? "active" : ""} onClick={() => this.navigateTab("summary")}>
                        Summary
                    </p>
                    <p className={this.state.active_tab === "telesales" ? "active" : ""} onClick={() => this.navigateTab("telesales")}>
                        Telesales
                    </p>
                    <p className={this.state.active_tab === "linkedin" ? "active" : ""} onClick={() => this.navigateTab("linkedin")}>
                        LinkedIn
                    </p>
                    <p className={this.state.active_tab === "email" ? "active" : ""} onClick={() => this.navigateTab("email")}>
                        Email
                    </p>
                </div>

                {/* Add lead form */}
                <AddSDR active={this.props.active} client={this.props.client} dismiss={this.props.dismiss} tab={this.state.active_tab} />
            </Modal>
        );
    }
}

export default AddSDRModal;
