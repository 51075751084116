import firebase from "firebase";

let config;

// if (window.location.hostname === "localhost" || window.location.hostname === "curral-crm-dev.web.app") {
//     config = {
//         apiKey: "AIzaSyBbpP2WUhbfJUNn8qcBPUdrD9JLRQlrtDw",
//         authDomain: "curral-crm-dev.firebaseapp.com",
//         databaseURL: "https://curral-crm-dev.firebaseio.com",
//         projectId: "curral-crm-dev",
//         storageBucket: "curral-crm-dev.appspot.com",
//         messagingSenderId: "101730334484",
//         appId: "1:101730334484:web:3d054a0380327d4ff7b401",
//         measurementId: "G-V8R40N1JSX",
//     };
// } else {
    config = {
        apiKey: "AIzaSyBt1_xAvu1K1IJ6zCBc84SMa3T1Zx8d-uk",
        authDomain: "curral-crm.firebaseapp.com",
        databaseURL: "https://curral-crm.firebaseio.com",
        projectId: "curral-crm",
        storageBucket: "curral-crm.appspot.com",
        messagingSenderId: "386645150396",
        appId: "1:386645150396:web:31191aec6d95d513d15eb9",
        measurementId: "G-WC2NVKL8CG",
    };
// }

firebase.initializeApp(config);
firebase.analytics();

/**
 * Firebase performance
 * @type {const}
 */
const performance = firebase.performance();

/**
 * Firebase authentication
 * @type {const}
 */
const auth = firebase.auth();

/**
 * Firestore
 * @type {const}
 */
const db = firebase.firestore();

/**
 * Firebase functions
 * @type {const}
 */
const fun = firebase.functions();

/**
 * Firebase storage
 * @type {const}
 */
const storage = firebase.storage();

/**
 * Array union function from firestore
 * @type {const}
 */
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

/**
 * Array remove function from firestore
 * @type {const}
 */
const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

/**
 * Grabs a timestamp value from firestore
 * @type {const}
 */
const getTimestamp = async () => {
    return firebase.firestore.Timestamp.fromDate(new Date());
};

/**
 * Increments a document by 1
 * @type {const}
 */
const increment = firebase.firestore.FieldValue.increment(1);

/**
 * Increments a document by a given amount
 * @type {const}
 */
const incrementBy = (by) => {
    firebase.firestore.FieldValue.increment(by);
};

/**
 * Decrements a document by 1
 * @type {const}
 */
const decrement = firebase.firestore.FieldValue.increment(-1);

/**
 * Decrements a document by a given amount
 * @type {const}
 */
const decrementBy = (by) => {
    firebase.firestore.FieldValue.increment(-Math.abs(parseInt(by)));
};

/**
 * Gets the document ID for performing queries to the database
 * @type {const}
 */
const documentID = firebase.firestore.FieldPath.documentId();

/**
 * Holds function for sending transaction emails
 * @type {const}
 */
const mailSendFunc = fun.httpsCallable("sendCallableEmail");

/**
 * Are we developing locally? If so attach the emulators
 */
// if (window.location.hostname === "localhost") {

//     db.useEmulator("localhost", 3232);

//     fun.useEmulator("localhost", 5001);
// }

export { performance, firebase, auth, db, fun, storage };
export { mailSendFunc };
export { arrayUnion, arrayRemove, increment, getTimestamp, incrementBy, decrement, decrementBy, documentID };
