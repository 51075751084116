export const industries = {
    0: "Accounting",
    1: "Airlines/Aviation",
    2: "Alternative Dispute Resolution",
    3: "Alternative Medicine",
    4: "Animation",
    5: "Apparel & Fashion",
    6: "Architecture & Planning",
    7: "Arts and Crafts",
    8: "Automotive",
    9: "Aviation & Aerospace",
    10: "Banking",
    11: "Biotechnology",
    12: "Broadcast Media",
    13: "Building Materials",
    14: "Business Supplies and Equipment",
    15: "Capital Markets",
    16: "Chemicals",
    17: "Civic & Social Organization",
    18: "Civil Engineering",
    19: "Commercial Real Estate",
    20: "Computer & Network Security",
    21: "Computer Games",
    22: "Computer Hardware",
    23: "Computer Networking",
    24: "Computer Software",
    25: "Construction",
    26: "Consumer Electronics",
    27: "Consumer Goods",
    28: "Consumer Services",
    29: "Cosmetics",
    30: "Dairy",
    31: "Defense & Space",
    32: "Design",
    33: "Education Management",
    34: "E-Learning",
    35: "Electrical/Electronic Manufacturing",
    36: "Entertainment",
    37: "Environmental Services",
    38: "Events Services",
    39: "Executive Office",
    40: "Facilities Services",
    41: "Farming",
    42: "Financial Services",
    43: "Fine Art",
    44: "Fishery",
    45: "Food & Beverages",
    46: "Food Production",
    47: "Fund-Raising",
    48: "Furniture",
    49: "Gambling & Casinos",
    50: "Glass",
    51: "Government Administration",
    52: "Government Relations",
    53: "Graphic Design",
    54: "Health",
    55: "Higher Education",
    56: "Hospital & Health Care",
    57: "Hospitality",
    58: "Human Resources",
    59: "Import and Export",
    60: "Individual & Family Services",
    61: "Industrial Automation",
    62: "Information Services",
    63: "Information Technology and Services",
    64: "Insurance",
    65: "International Affairs",
    66: "International Trade and Development",
    67: "Internet",
    68: "Investment Banking",
    69: "Investment Management",
    70: "Judiciary",
    71: "Law Enforcement",
    72: "Law Practice",
    73: "Legal Services",
    74: "Legislative Office",
    75: "Leisure",
    76: "Libraries",
    77: "Logistics and Supply Chain",
    78: "Luxury Goods & Jewelry",
    79: "Machinery",
    80: "Management Consulting",
    81: "Maritime",
    82: "Market Research",
    83: "Marketing and Advertising",
    84: "Mechanical or Industrial Engineering",
    85: "Media Production",
    86: "Medical Devices",
    87: "Medical Practice",
    88: "Mental Health Care",
    89: "Military",
    90: "Mining & Metals",
    91: "Mobile Games",
    92: "Motion Pictures and Film",
    93: "Museums and Institutions",
    94: "Music",
    95: "Nanotechnology",
    96: "Newspapers",
    97: "Non-Profit Organization Management",
    98: "Oil & Energy",
    99: "Online Media",
    100: "Outsourcing/Offshoring",
    101: "Package/Freight Delivery",
    102: "Packaging and Containers",
    103: "Paper & Forest Products",
    104: "Performing Arts",
    105: "Pharmaceuticals",
    106: "Philanthropy",
    107: "Photography",
    108: "Plastics",
    109: "Political Organization",
    110: "Primary/Secondary Education",
    111: "Printing",
    112: "Professional Training & Coaching",
    113: "Program Development",
    114: "Public Policy",
    115: "Public Relations and Communications",
    116: "Public Safety",
    117: "Publishing",
    118: "Railroad Manufacture",
    119: "Ranching",
    120: "Real Estate",
    121: "Recreational Facilities and Services",
    122: "Religious Institutions",
    123: "Renewables & Environment",
    124: "Research",
    125: "Restaurants",
    126: "Retail",
    127: "Security and Investigations",
    128: "Semiconductors",
    129: "Shipbuilding",
    130: "Sporting Goods",
    131: "Sports",
    132: "Staffing and Recruiting",
    133: "Supermarkets",
    134: "Telecommunications",
    135: "Textiles",
    136: "Think Tanks",
    137: "Tobacco",
    138: "Translation and Localization",
    139: "Transportation/Trucking/Railroad",
    140: "Utilities",
    141: "Venture Capital & Private Equity",
    142: "Veterinary",
    143: "Warehousing",
    144: "Wholesale",
    145: "Wine and Spirits",
    146: "Wireless",
    147: "Writing and Editing"
};