import React from 'react';

/**
 * Styles
 */
import './button.scss';

const Button = (props) => (
    <button className={['button', props.class].join(' ')}
        type={props.type}
        onClick={props.onClick}
        disabled={props.disabled}>
        {props.children}
    </button>
);

export default Button;