import React, { Component } from 'react';
import { db } from '../../utils/firebase';

/**
 * Components
 */
import Button from '../ui/button/button';
import AddAction from '../forms/add-action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

/**
 * Moment libary for parsing dates on the UI
 */
const moment = require('moment');

class Actions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            default_actions: []
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
        this.checkforUpdate();
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        this.checkforUpdate();
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Check for component load
     *
     * @function
     */
    checkforUpdate = () => {
        /**
         * Get the values from props
         */
        const { client, lead } = this.props;
        /**
         * If the prop values don't match the state
         */
        if (client !== this.state.clientID || lead !== this.state.leadID) {
            /**
             * Update teh state with the new IDs
             */
            this.setState({ clientID: client, leadID: lead }, () => {
                /**
                 * Then fetch the actions for these values
                 */
                this.fetchActions(client, lead);
            });
        }
    }

    /**
     * Fetch the actions for a particular client and lead
     *
     * @function
     *
     * @param {String} client ID string for the client
     * @param {String} lead ID string for the lead
     */
    fetchActions = (client, lead) => {
        /**
         * Setup a snapshot listener on the leads actions collection
         */
        db.collection(`clients/${client}/leads/${lead}/actions`).onSnapshot((actionsSnap) => {
            /**
             * Init a new empty function on each new snapshot
             */
            let actions = [...this.state.default_actions] || [];
            /**
             * Loop over the actions collected
             */
            actionsSnap.docChanges().forEach(async (change) => {
                if (change.type === 'added') {
                    actions.push({ id: change.doc.id, ...change.doc.data() });
                }
                if (change.type === 'modified') {
                    actions = actions.filter(lead => lead.id !== change.doc.id);
                    actions.push({ id: change.doc.id, ...change.doc.data() });
                }
                if (change.type === 'removed') {
                    actions = actions.filter(lead => lead.id !== change.doc.id);
                }
                /**
                 * Update the state defaults
                 */
                this.setState({ default_actions: actions }, () => {
                    /**
                     * Sort the leads before appending to state
                     */
                    actions.sort(this.sortByDate);
                    /**
                     * Then organise them into months for the UI
                     */
                    this._isMounted && this.setState({ actions });
                })
            });
        });
    }

    /**
     * Sort the actions by their date
     *
     * @function
     */
    sortByDate(a, b) {
        /**
         * Make sure they have the correct values needed
         */
        if (a.created && b.created) {
            /**
             * Sort by the date the lead was recorded
             */
            const date = b.created.seconds - a.created.seconds;
            if (date !== 0) {
                return date;
            }
            return 0;
        }
        return 0;
    }

    /**
     * Toggle the state to show and hide the add form
     *
     * @function
     */
    toggleAdding = () => {
        /**
         * Switch the state
         */
        this.setState({ adding: !this.state.adding });
    }

    /**
     * Remove the action
     *
     * @function
     */
    remove = (action_id) => {
        /**
         * Get the values from props
         */
        const { client, lead } = this.props;
        /**
         * Remove the action record from the scheduled collection if due to be sent
         */
        db.doc(`scheduled/${action_id}`).delete();
        /**
         * Then remove it from the lead collection
         */
        db.doc(`clients/${client}/leads/${lead}/actions/${action_id}`).delete();
    }

    /**
     *
     */
    render() {
        /**
         * If there are actions loaded in the state
         */
        return (
            <div className={['modal-form', this.props.active ? 'show' : ''].join(' ')}>
                {!this.state.adding &&
                    <Button onClick={this.toggleAdding}>Schedule Action</Button>}

                {/* Add action form */}
                {this.state.adding &&
                    <AddAction
                        client={this.props.client}
                        lead={this.props.lead}
                        active={this.state.adding}
                        dismiss={this.toggleAdding}
                        staff={this.props.staff} />}

                {this.state.actions ? this.state.actions.map((action, index) => (
                    <div key={action.id} className="engagement-item">
                        <div className="engagement-date">
                            <p>{action.created && moment.unix(action.action_on.seconds).format('DD/MM/YYYY')}</p>
                            <small>{action.created && moment.unix(action.action_on.seconds).format('HH:mm')}</small>
                        </div>
                        <div className="engagement-details">
                            <h6>{action.title}</h6>
                            <p>{action.message}</p>
                        </div>
                        <div className="action-remove" onClick={() => this.remove(action.id)}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </div>
                    </div>
                )) : <><br /><p>No actions yet</p></>}
            </div>
        );
    }
}

export default Actions;