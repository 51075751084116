// Authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

// Alerts
export const ALERT_PUSH = "ALERT_PUSH";

export const Filter = "FilterLeads"

export const getUsers = "getAllusers";

export const getAllUsers="getUsers";

export const getAllLeads = "get_Leads"

export const showingSDR = "showingSDR"

export const showingLead ="showingLead"