import React, { Component } from 'react';
import { db } from '../../utils/firebase';

/**
 * Datetime picker
 */
import Datetime from 'react-datetime';

/**
 * Components
 */
import Input from '../ui/input/input';
import TextArea from '../ui/input/textarea';
import Button from '../ui/button/button';

/**
 * Moment libary
 */
const moment = require('moment');

class AddEngagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            created:Datetime.moment()
        };
    }

    /**
     * Record the engagement to the database
     *
     * @function
     *
     * @param {*} e
     */
    recordEngagement = () => {
        /**
         * Reset the errors
         */
        this.setState({ errors: {} });
        /**
         * Get the client and lead data from props
         */
        const { client, lead } = this.props;
        /**
         * Get the engagement data from the state
         */
        const { title, message, created } = this.state;
        /**
         * Check for missing inputs
         */
        const errors = this.checkInputs();
        /**
         * If there were no errors
         */
        if (!errors) {
            /**
             * Add the engagement to the lead document
             */
            db.collection(`clients/${client}/leads/${lead}/engagements`).add({
                title, message, created
            });
            /**
             * Hide the form
             */
            this.props.dismiss();
        }
    }

    /**
     * Check for blank fields
     *
     * @function
     */
    checkInputs = () => {
        /**
         * Get the engagement data from the state
         */
        const { title, message, created } = this.state;
        /**
         * If no title was set
         */
        if (!title) {
            this.setState({ errors: { title: 'Please enter the engagement title' } });
            return true;
        }
        /**
         * Does it have a message
         */
        if (!message) {
            this.setState({ errors: { message: 'Please enter the engagement message' } });
            return true;
        }
        /**
         * Was the date recorded
         */
        if (!created || !created.readable) {
            this.setState({ errors: { created: 'Please select a recorded date/time' } });
            return true;
        }
    }

    /**
     * Handle the event of an input change
     *
     * @function
     *
     * @param {Object} e Event object
     */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    /**
     * Update the state with a formatted verison of the datetime picker result
     *
     * @function
     *
     * @param {Object} date Date object from the datetime picker
     */
    handleDateChange = (date) => {
        /**
         * Parse the date into different string types
         */
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        /**
         * Then set the state with them
         */
        this.setState({
            ...this.state,
            created: {
                seconds: seconds,
                readable: readable
            }
        });
        // console.log(this.state)
    }

    render() {
        return (
            <div className={['modal-form engagement-form', this.props.active ? 'show' : ''].join(' ')}>
                <Input
                    id="title"
                    placeholder="Title"
                    value={this.state.title}
                    error={this.state.errors.title}
                    onChange={this.handleInputChange} />
                <TextArea
                    id="message"
                    placeholder="Message"
                    value={this.state.message}
                    error={this.state.errors.message}
                    onChange={this.handleInputChange} />
                <Datetime
                    locale="en"
                    // value={this.state.created.readable}
                    inputProps={{ placeholder: 'Engagement date' }}
                    onChange={date => this.handleDateChange(date)} />
                {this.state.errors.created && <p className="input-error">{this.state.errors.created}</p>}
                <Button onClick={this.recordEngagement}>Save Engagement</Button>
            </div>
        );
    }
}

export default AddEngagement;