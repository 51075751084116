import React, { Component } from 'react';
import { db } from '../../../utils/firebase';

/**
 * UI Components
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class Stakeholder extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this.fetchStakeholder();
    }

    /**
     * Fetch the stakeholders details from the database
     *
     * @function
     */
    fetchStakeholder = () => {
        /**
         * Get the userID from props
         */
        const { user } = this.props;
        /**
         * Get their details from the database
         */
        db.doc(`users/${user}`).get().then((stakeholder) => {
            /**
             * Make sure they exist
             */
            if (stakeholder.exists) {
                /**
                 * Update the state
                 */
                this.setState({ ...stakeholder.data() });
            }
        });
    }

    render() {
        /**
         * Has the user details loaded
         */
        if (this.state.name || this.state.email) {
            return (
                <div>
                    <div className="stakeholder-name">
                        <p>{this.state.name}</p>
                        {this.props.notifications && <FontAwesomeIcon icon={faCheckDouble} />}
                    </div>
                    <div className="stakeholder-email">
                        <p>{this.state.email}</p>
                    </div>
                    <div className="stakeholder-remove" onClick={() => this.props.remove(this.props.user)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                </div>
            );
        }

        return ('Cannot fetch details...');
    }
}

export default Stakeholder;