import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { db, auth } from "../../../utils/firebase";
import "./navigation.scss";

/**
 * UI components
 */
import Input from "../../../components/ui/input/input";
import Checkbox from "../../../components/ui/input/checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCogs, faCaretDown } from "@fortawesome/free-solid-svg-icons";

/**
 * Functional component to return the navigation
 */
function Navigation(props) {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [search, setSearch] = useState("");
    const [showArchived, setShowArchived] = useState(false);

    /**
     * Deconstruct data from the props passed down
     */
    const { add, edit, load, current, is_client, show_panel =true } = props;
    console.log(is_client,"CLIENT")
    // console.log(props,"NAMPRSOA")
    /**
     * When the showArchived toggle is updated
     */
    useEffect(() => {
        /**
         * If this user is a client account
         */
        if (is_client) {
            /**
             * Fetch the clients for this user
             */
            fetchUserClients();
        } else {
            /**
             * Reset the clients array in the state
             */
            setClients([]);
            /**
             * Otherwise fetch all the clients
             */
            fetchAllClients();
        }
    }, [showArchived]);

    /**
     * When the search query is updated
     */
    useEffect(() => {
        /**
         * Create a copy of the clients array
         */
        let clientsCopy = [...clients];
        /**
         * Filter this new array by a lowercase version of the name
         */
        clientsCopy = clientsCopy.filter((client) => client.name.toLowerCase().includes(search.toLowerCase()));
        /**
         * Then set the filtered arry into the filteredClients state
         */
        setFilteredClients(clientsCopy)
    }, [search]);

    /**
     * Fetch the client accounts that the current user has access to
     */
    const fetchUserClients = async () => {
        /**
         * Get the clients this user has access to
         */
        const user_clients = await db.doc(`users/${auth.currentUser.uid}`)
            .get().then((userDoc) => {
                /**
                 * Return the clients array for this user
                 */
                return userDoc.data().clients;
            });
        /**
         * Pull all the client accounts from the database
         */
        db.collection("clients")
            .where(firebase.firestore.FieldPath.documentId(), "in", user_clients)
            .get().then((clientDocs) => {
                /**
                 * Loop over the client documents found
                 */
                clientDocs.forEach((clientDoc) => {
                    setClients((clients) => [
                        ...clients,
                        {
                            id: clientDoc.id,
                            ...clientDoc.data(),
                        }
                    ]);
                });
            });
    }

    /**
     * Fetch all the clients currently on the CRM thatare no archived
     */
    const fetchAllClients = async () => {
        /**
         * Pull all the client accounts from the database
         */
        db.collection("clients")
            .orderBy("name")
            .onSnapshot((clientsSnap) => {
                /**
                 * Loop over the client documents found
                 */
                clientsSnap.docChanges().forEach((change) => {
                    /**
                     * Deconstruct the archived status from the client document
                     */
                    const { archived } = change.doc.data();
                    /**
                     * Client document added
                     */
                    if (change.type === "added") {
                        /**
                         * Check to see if either we are looking for archived clients, and this is 
                         * one - or if we are not looking for archived clients, and this is one
                         */
                        if ((!showArchived && !archived) || (showArchived && archived)) {
                            setClients((clients) => [
                                ...clients,
                                {
                                    id: change.doc.id,
                                    ...change.doc.data(),
                                }
                            ]);
                        }
                    }
                    /**
                     * Client document updated
                     */
                    if (change.type === "modified") {
                        if ((!showArchived && !archived) || (showArchived && archived)) {
                            setClients((clients) => {
                                let updatedClients = [...clients];
                                for (let i in clients) {
                                    if (clients[i].id === change.doc.id) {
                                        updatedClients[i] = {
                                            id: change.doc.id,
                                            ...change.doc.data(),
                                        };
                                        break;
                                    }
                                }
                                return updatedClients;
                            });
                        } else {
                            setClients((clients) => clients.filter((client) => client.id !== change.doc.id));
                        }
                    }
                    /**
                     * Client document removed
                     */
                    if (change.type === "removed") {
                        setClients((clients) => clients.filter((client) => client.id !== change.doc.id));
                    }
                });
            });
    }

    return (
        <div id="clients-navigation" className={[show_panel && "show"].join(" ")}>
            <div id="client-nav-pullout">
                {is_client ? "Accounts" : "Clients"} <FontAwesomeIcon icon={faCaretDown} />
            </div>

            <div id="client-nav-inner">
                {/* If this user is a client account */}
                {is_client && <h2>Accounts</h2>}

                {/* If this user is a staff account */}
                {!is_client &&
                    <>
                        <h2>Clients</h2>
                        <Input
                            id="curral-client-list-search"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Start typing..." />

                        <div className="clients-add" onClick={add}>
                            <FontAwesomeIcon icon={faPlus} /><p>Add Client</p>
                        </div>

                        <div className="show-archived-checkbox">
                            <Checkbox
                                label="Archived clients"
                                toggle={() => setShowArchived(!showArchived)}
                                checked={showArchived} />
                        </div>
                    </>
                }

                {/* Client list */}
                <div className="client-list">
                    {/* Are there any filtered clients to show? */}
                    {(filteredClients.length > 0) &&
                        <>
                            {filteredClients.map((client) => (
                                <div
                                    key={client.id}
                                    className={[
                                        "client-item",
                                        (current === client.id) && "active"
                                    ].join(" ")}>

                                    {/* When the client name is clicked, load them into DOM */}
                                    <p onClick={() => load(client.id)}>
                                        {client.name}
                                    </p>

                                    {/* If this is a staff user logged in, show the edit icon */}
                                    {!is_client &&
                                        <div className="edit-icon" onClick={() => edit(client)}>
                                            <FontAwesomeIcon icon={faCogs} />
                                        </div>
                                    }
                                </div>
                            ))}
                        </>
                    }

                    {/* If there are no filtered clients to show */}
                    {(filteredClients.length === 0) &&
                        <>
                            {clients.map((client) => (
                                <div
                                    key={client.id}
                                    className={[
                                        "client-item",
                                        (current === client.id) && "active"
                                    ].join(" ")}>

                                    {/* When the client name is clicked, load them into DOM */}
                                    <p onClick={() => load(client.id)}>
                                        {client.name}
                                    </p>

                                    {/* If this is a staff user logged in, show the edit icon */}
                                    {!is_client &&
                                        <div className="edit-icon" onClick={() => edit(client)}>
                                            <FontAwesomeIcon icon={faCogs} />
                                        </div>
                                    }
                                </div>
                            ))}
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default Navigation;