import React from 'react';
import './errors.scss';

const Error404 = () => (
    <div id="error-holder">
        <div className="error-title">
            404
        </div>
        <div className="error-subtitle">
            Page not found
        </div>
    </div>
);

export default Error404;