import React, { Component } from 'react';
import firebase from 'firebase';
import { db, auth, getTimestamp } from '../../utils/firebase';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';

import Logo from '../../assets/images/curral-logo.png';
import Ripple from '../../assets/images/ripple.svg';

import Button from '../../components/ui/button/button';
import Input from '../../components/ui/input/input';

/**
 * Styles
 */
import './login.scss';
import { Redirect } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: {
                email: '',
                password: ''
            },
            passwordReset: {
                active: false
            },
            loading: true,
            user: null
        }
    }

    componentDidMount() {
        this.authObserve();
    }

    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    authObserve = () => {
        this.unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
            if (user) {
                const timestamp = await getTimestamp();
                db.doc(`users/${auth.currentUser.uid}`).get().then(userDoc => {
                    if (userDoc.exists) {
                        this.loadUserDocument(user);
                        db.doc(`users/${auth.currentUser.uid}`).update({ last_signin: timestamp });
                    }
                });
            } else {
                this.setState({ loading: false });
            }
        });
    }

    logUserIn = (e) => {
        e.preventDefault();

        const { email, password } = this.state.login;
        this.setState({ error: null });

        if (!email || !password) {
            this.setState({ error: 'Please complete all the fields.' });
            return false;
        }

        firebase.auth().signInWithEmailAndPassword(email, password).then((result) => {
            this.loadUserDocument(result.user);
        }).catch((err) => {
            switch (err.code) {
                case 'auth/user-not-found':
                    this.setState({ error: 'There is no account associated with these credentials.' });
                    break;
                case 'auth/wrong-password':
                    this.setState({ error: 'Please check your credentials and try again.' });
                    break;
                default:
                    this.setState({ error: 'There was an error, Please try again.' });
            }
        });
    }

    loadUserDocument = async (authUser) => {
        if (authUser) {
            const user = await this.checkForExistingUser(authUser);
            if (user.exists) {
                this.loadStateWithUser(user.user);
            }
        }
    }

    checkForExistingUser = async (user) => {
        return await db.doc(`users/${user.uid}`).get().then(userDoc => {
            if (userDoc.exists) {
                return { exists: true, user: { id: userDoc.id, ...userDoc.data() } }
            } else {
                return { exists: false };
            }
        });
    }

    loadStateWithUser(user) {
        this.props.loginSuccess({
            id: user.id,
            ...user
        });
        window.location.href = '/clients';
    }

    handleLoginFieldUpdate = (e) => {
        this.setState({
            ...this.state,
            login: {
                ...this.state.login,
                [e.target.name]: e.target.value
            }
        });
    }

    handlePasswordFieldUpdate = (e) => {
        this.setState({
            ...this.state,
            passwordReset: {
                ...this.state.passwordReset,
                [e.target.name]: e.target.value
            }
        });
    }

    togglePasswordReset = (success) => {
        this.setState({
            ...this.state,
            error: '',
            passwordReset: {
                active: !this.state.passwordReset.active,
                success: success ? 'If that email exists, we\'ve sent a reset link for you to follow.' : ''
            }
        });
    }

    sendPasswordReset = async () => {
        /**
         * Check to make sure there is an email input
         */
        if (!this.state.passwordReset.email) {
            /**
             * Return an error
             */
            this.setState({ error: 'Please enter an email address' });
        } else {
            /**
             * Send the password reset link
             */
            const success = await firebase.auth().sendPasswordResetEmail(this.state.passwordReset.email).then(function () {
                return true
            }).catch(function (error) {
                return false;
            });
            if (success) {
                /**
                 * Show a success message inline with the form
                 */
                this.togglePasswordReset(true);
            }
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div id="login-wrap">
                <div id="login-form-wrap">
                    <div id="login-logo">
                        <img src={Logo} alt="Curral Logo" />
                    </div>
                    {/* Login form */}
                    {!this.state.passwordReset.active &&
                        <>
                            <div id="login-fields">
                                <Input
                                    id="email"
                                    type="email"
                                    value={this.state.login.email}
                                    onChange={this.handleLoginFieldUpdate}
                                    placeholder="Email:" />
                                <Input
                                    id="password"
                                    type="password"
                                    value={this.state.login.password}
                                    onChange={this.handleLoginFieldUpdate}
                                    placeholder="Password:" />
                                {/* Is there an error */}
                                {this.state.error &&
                                    <div id="login-error">
                                        {this.state.error}
                                    </div>}
                                <Button onClick={this.logUserIn}>Login</Button>
                                {/* If the password was reset */}
                                {this.state.passwordReset.success &&
                                    <div id="password-reset-success">
                                        {this.state.passwordReset.success}
                                    </div>}
                            </div>
                            <div id="password-reset">
                                <p onClick={this.togglePasswordReset}>Forgot your password?</p>
                            </div>
                        </>}
                    {/* Reset password form */}
                    {this.state.passwordReset.active &&
                        <>
                            <div id="login-fields">
                                <Input
                                    id="email"
                                    type="email"
                                    value={this.state.passwordReset.email}
                                    onChange={this.handlePasswordFieldUpdate}
                                    placeholder="Email:" />
                                {this.state.error ?
                                    <div id="login-error">
                                        {this.state.error}
                                    </div> : null}
                                <Button onClick={this.sendPasswordReset}>Send Reset Link</Button>
                            </div>
                            <div id="password-reset">
                                <p onClick={() => this.togglePasswordReset(false)}>Back to login</p>
                            </div>
                        </>}
                </div>
                {this.state.loading ?
                    <div id="login-loading">
                        <img src={Ripple} alt="Loading SVG" />
                    </div> : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user
    };
}

const mapDispatchToProps = dispatch => {
    return {
        loginSuccess: (payload) => dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: payload })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);