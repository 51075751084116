import React, { Component } from "react";
import Navigation from "./navigation/navigation";
import { db, auth } from "../../utils/firebase";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";

/**
 * Styles
 */
import "./clients.scss";

/**
 * UI Components
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Leads from "./leads/leads";
import ViewClient from "../../components/modal/types/view-client";
import AddClientModal from "../../components/modal/types/add-client";
import AddLeadModal from "../../components/modal/types/add-lead";
import AddSDRModal from "../../components/modal/types/add-sdr";
import AddFilterModal from "../../components/modal/types/add-filter";

class Clients extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: {},
            // staff:true
        };

    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
        this.checkIfStaff();
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Check if current user is staff member
     *
     * @function
     */
    checkIfStaff = () => {
        /**
         * Get the data from firestore
         */
        db.collection("users")
            .where("email", "==", auth.currentUser.email)
            .where("type", "==", "staff")
            // .where("is_active", "==", true)
            .get()
            .then((staffDocs) => {
                /**
                 * Was there a staff document found?
                 */
                if (staffDocs.size > 0) {
                    /**
                     * Then update the state with them
                     */
                    this.setState({ staff: true });
                } else {
                    /**
                     * If there wasn't, false the state and fetch the client they need
                     */
                    this.setState({ staff: false }, () => {
                        this.fetchUserClient();
                    });
                }
            });
    };

    /**
     * If the current user only has one client assigned to them fetch that
     *
     * @function
     */
    fetchUserClient = () => {
        /**
         * Get the user document
         */
        db.doc(`users/${auth.currentUser.uid}`)
            .get()
            .then((userDoc) => {
                /**
                 * If the document exists
                 */
                if (userDoc.exists) {
                    /**
                     * How many clients is this user tied to?
                     */
                    console.log(userDoc.data().clients, "====asnascasbchasa");
                    if (userDoc.data().clients.length > 1) {
                        /**
                         * Change the state to refelect their client
                         */
                        
                        this.setState({ clients: userDoc.data().clients });
                    } else {
                        /**
                         * Change the state to refelect their client
                         */
                        this.setState({ client: { id: userDoc.data().clients[0] } });
                    }
                }
            });
    };

    /**
     * Toggle the add client modal to slide out
     *
     * @function
     */
    toggleClientAdd = () => {
        this.setState({ add_client: !this.state.add_client });
    };

    /**
     * Toggle the edit client modal to slide out
     *
     * @function
     *
     * @param {String} client Client ID passed up from props of nav
     */
    toggleClientEdit = (client) => {
        /**
         * Update the state with the client ID
         */
        this.setState({
            edit_client_id: client.id,
            edit_client: !this.state.edit_client,
        });
    };

    /**
     * Toggle the add clead modal to slide out
     *
     * @function
     */
    toggleAddLead = () => {
        this.setState({ add_lead: !this.state.add_lead });
    };

    /**
     * Toggle the add clead modal to slide out
     *
     * @function
     */
    toggleAddSDR = () => {
        this.setState({ add_sdr: !this.state.add_sdr });
    };

    toggleFilter = () => {
        this.setState({ addFilter: !this.state.addFilter })
    }

    /**
     * Stream the client data down onto the page
     *
     * @function
     */
    setupClientListenser = (id) => {
        /**
         * Database listener
         */
        db.doc(`clients/${id}`).onSnapshot((snap) => {
            /**
             * Update the state
             */
            this._isMounted && this.setState({ client: { ...snap.data(), id: snap.id } });
        });
    };

    /**
     * Load the client Id into state
     *
     * @function
     *
     * @param {String} id Client Id to load into state
     */
    loadClient = (id) => {
        /**
         * Load the client ID into state
         */
        this.setState({ client: { id } }, () => {
            /**
             * Pull client details to show the name above the leads
             */
            this.setupClientListenser(id);
        });
    };

    /**
     * Dismiss the add client modal
     *
     * @function
     */
    dismissAddClient = () => {
        /**
         * Update the state to hide the lead
         */
        this.setState({ add_client: false });
    };

    /**
     * Dismiss the client modal
     *
     * @function
     */
    dismissEditClient = () => {
        /**
         * Update the state to hide the lead
         */
        this.setState({ edit_client: false });
    };

    /**
     * Dismiss the add lead modal
     *
     * @function
     */
    dismissAddLead = () => {
        /**
         * Update the state to hide the lead
         */
        this.setState({ add_lead: false });
    };

    /**
     * Dismiss the add lead modal
     *
     * @function
     */
    dismissAddSDR = () => {
        /**
         * Update the state to hide the lead
         */
        this.setState({ add_sdr: false });
    };

    dismissFilter = () => {
        this.setState({ addFilter: false });
    }

    render() {
        console.log(this.state, "MAIN CLIENT")
        return (
            <>
                <div id="clients-view">
                    {/* Show the navigation if the user logged in is a staff member */}
                    {this.state.staff && (
                        <Navigation
                            add={this.toggleClientAdd}
                            load={this.loadClient}
                            edit={this.toggleClientEdit}
                            current={this.state.client.id}
                            show_panel={this.state.client_panel}
                            user={this.state.client}
                        />
                    )}

                    {(this.state.clients?.length > 1) &&
                        (

                            <Navigation
                                load={this.loadClient}
                                current={this.state.client.id}
                                show_panel={this.state.client_panel}
                                is_staff={this.state.staff}
                                is_client="true"
                                user={this.state.client}

                            />
                        )}
                    {/* {(!this.state.staff && (

                        <Navigation
                            add={this.toggleClientAdd}
                            load={this.loadClient}
                            edit={this.toggleClientEdit}
                            current={this.state.client.id}
                            show_panel={this.state.client_panel}
                            // is_client="false"
                            user={this.state.client}
                        />
                    ))} */}

                    {/* Lead list and client title */}
                    <div className="lead-list">
                        {/* Add client modal */}
                        <AddClientModal active={this.state.add_client} dismiss={this.dismissAddClient} />

                        {/* View client modal */}
                        <ViewClient active={this.state.edit_client} client={this.state.edit_client_id} dismiss={this.dismissEditClient} />

                        {/* Print the leads */}
                        {this.state.client.id && (
                            <>
                                {/* Client name */}
                                <div className="client-name">
                                    <p>{this.state.client.name}</p>
                                </div>

                                {/* If the staff member is logged in give them access to add leads */}
                                {this.state.staff && (
                                    <div className="add-toggles">
                                        <div className="lead-add" onClick={this.toggleAddLead}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p>New Lead</p>
                                        </div>
                                        <div className="lead-add" onClick={this.toggleAddSDR}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p>SDR Notes</p>
                                        </div>
                                        {/* <div className="lead-add" onClick={this.toggleFilter}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p>Leads Filter</p>
                                        </div> */}

                                        {/* Add lead modal */}

                                        <AddLeadModal
                                            active={this.state.add_lead} 
                                            client={this.state.client.id} 
                                            clientPoundValue={this.state.client?.pound_value} 
                                            dismiss={this.dismissAddLead} 
                                        />
                                        {/* Add SDR modal */}
                                        <AddSDRModal 
                                            active={this.state.add_sdr} 
                                            client={this.state.client.id} 
                                            dismiss={this.dismissAddSDR} 
                                        />

                                        {/* <AddFilterModal active={this.state.addFilter} client={this.state.client.id}  dismiss={this.dismissFilter} /> */}
                                    </div>
                                )}

                                {/* Leads table */}
                                <div id="leads-table">
                                    <Leads
                                        // clientData={this.state.client}
                                        client={this.state.client.id} 
                                        clientName={this.state.client.name} 
                                        edit={this.toggleEditLead} 
                                        up={this.state.add_lead} 
                                        sd={this.state.add_sdr} 
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps, null)(Clients);
