import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { db } from "../../utils/firebase";
import moment from "moment";
import "./reports.scss";

/**
 * UI components
 */
import Input from "../../components/ui/input/input";
import Select from "../../components/ui/selects/select";
import Report from "./report/report";

/**
 * Download libaries
 */
const { jsPDF } = require("jspdf");
const html2canvas = require("html2canvas");

/**
 * Functional component to return the reports page
 */
function Reports() {
    const [client, setClient] = useState(null);
    const [reportType, setReportType] = useState("");
    const [clients, setClients] = useState({});

    const [reportTitle, setReportTitle] = useState("");

    const [connectionsSent, setConnectionsSent] = useState("");
    const [connectionsAccepted, setConnectionsAccepted] = useState("");
    const [messagesSent, setMessagesSent] = useState("");
    const [messagesEngaged, setMessagesEngaged] = useState("");

    const [leadsAppointment, setLeadsAppointment] = useState("");
    const [leadnoshow, setLeadnoshow] = useState("");
    const [leadsHot, setLeadsHot] = useState("");
    const [leadsModerate, setLeadsModerate] = useState("");
    const [leadsCold, setLeadsCold] = useState("");

    const [profileViews, setProfileViews] = useState("");
    const [profileViewsPeriod, setProfileViewsPeriod] = useState("");
    console.log(leadsAppointment, "APPOINTMENT")
    /**
     * Get the start and end of the previous month
     */
    const startOfMonth = moment().subtract(1, "months").startOf("month").valueOf();
    const endOfMonth = moment().subtract(1, "months").endOf("month").valueOf();

    /**
     * Then generate firebase timestamps with those milliseconds
     */
    const timestampStart = firebase.firestore.Timestamp.fromMillis(startOfMonth);
    const timestampEnd = firebase.firestore.Timestamp.fromMillis(endOfMonth);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Fetch all the clients from the database
         */
        db.collection("clients")
            .orderBy("name")
            .get().then((clientDocs) => {
                clientDocs.forEach((clientDoc) => {
                    /**
                     * Deconstruct their name from the document
                     */
                    const { name } = clientDoc.data();
                    const { archived } = clientDoc.data();
                    // console.log("clientDoc.archived",archived);
                    /**
                     * Set that and their ID into the state object
                     */
                    if (archived == false && archived !== true) {

                        setClients((clients) => ({
                            ...clients,
                            [clientDoc.id]: name,
                        }));
                    }
                });
            });
    }, []);

    /**
     * When the client object is updated
     */
    useEffect(() => {
        /**
         * Make sure there is a client ID present
         */
        if (client?.id) {
            /**
             * Pull the ID from the client object
             */
            const { id } = client;
            /**
             * Call the leads collection and find the leads that fall inside the dates
             */
            db.collection(`clients/${id}/leads`)
                .where("created", ">=", timestampStart)
                .where("created", "<=", timestampEnd)
                .get().then((leadDocs) => {
                    /**
                     * Setup some variables for temporarily sotring the lead counts
                     */
                    let appointment = 0;
                    let appointmentnoshow = 0
                    let hot = 0;
                    let moderate = 0;
                    let cold = 0;
                    /**
                     * Loop through all the leads found
                     */
                    leadDocs.forEach((leadDoc) => {
                        /**
                         * Pull the lead type from the document
                         */
                        const { type } = leadDoc.data();
                        console.log(type)
                        /**
                         * Increment the lead count depending on what the lead type was
                         */
                        if (type === "9_Appointment") {
                            appointment++;
                        } else if (type === "9_Appointment-noshow") {
                            appointmentnoshow++;
                        } else if (type === "a_Hot") {
                            hot++;
                        } else if (type === "b_Moderate") {
                            moderate++;
                        } else if (type === "c_Low") {
                            cold++;
                        }
                    });
                    /**
                     * Update the state with the lead type counts
                     */
                    setLeadsAppointment(appointment);
                    setLeadnoshow(appointmentnoshow);
                    setLeadsHot(hot);
                    setLeadsModerate(moderate);
                    setLeadsCold(cold);
                    setMessagesEngaged(appointment + appointmentnoshow + hot + moderate + cold);
                });
        }
    }, [client]);

    /**
     * 
     */
    const downloadReport = () => {
        html2canvas(document.querySelector("#report"), {
            backgroundColor: "#ffffff",
            scale: 1,
            removeContainer: true,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const report = new jsPDF({ orientation: "landscape" });
            report.addImage(imgData, "PNG", 0, 0);
            report.save(`${client.name} ${moment().subtract(1, "month").startOf("month").format("MMMM YYYY")} - Curral Ltd Report.pdf`);
        });
    }

    return (
        <div id="reporting-window">
            <div className="report-title">
                <p>Reporting {client && `for ${client.name}`}</p>
            </div>

            {/* Client and report type dropdowns */}
            <div className="report-options">
                <Select
                    placeholder="Choose client:"
                    options={clients}
                    value={client?.name}
                    onSelect={(option) => setClient({ id: option.option, name: option.value })} />
                <p className="clear-report-input" onClick={() => setClient(null)}>clear</p>

                {/* If there is a client name loaded into the state */}
                {client?.name &&
                    <>
                        <Select
                            placeholder="Report type:"
                            options={{
                                "Monthly Prospecting": "Monthly Prospecting",
                                "Qualified": "Qualified",
                            }}
                            value={reportType}
                            onSelect={(option) => setReportType(option.value)} />
                        <p className="clear-report-input" onClick={() => setReportType("")}>clear</p>
                    </>
                }
            </div>

            {/* When there is a report type in the state */}
            {reportType &&
                <div className="report-fields">
                    {(reportType === "Qualified") &&
                        <div className="report-field small">
                            <h6>Report Title</h6>
                            <div className="fields">
                                <Input
                                    placeholder="Setup Report"
                                    value={reportTitle}
                                    onChange={(e) => setReportTitle(e.target.value)} />
                            </div>
                        </div>
                    }

                    <div className="report-field small">
                        <h6>Connections</h6>
                        <div className="fields">
                            <Input
                                placeholder="Sent"
                                value={connectionsSent}
                                onChange={(e) => setConnectionsSent(e.target.value)} />
                            <Input
                                placeholder="Accepted"
                                value={connectionsAccepted}
                                onChange={(e) => setConnectionsAccepted(e.target.value)} />
                        </div>
                    </div>

                    <div className="report-field small">
                        <h6>Messages</h6>
                        <div className="fields">
                            <Input
                                placeholder="Sent"
                                value={messagesSent}
                                onChange={(e) => setMessagesSent(e.target.value)} />
                            <Input
                                placeholder="Engaged"
                                value={messagesEngaged}
                                onChange={(e) => setMessagesEngaged(e.target.value)} />
                        </div>
                    </div>

                    <div className="report-field">
                        <h6>Leads</h6>
                        <div className="fields">
                            {/* {(reportType === "Qualified") &&()} */}

                                <Input
                                title="Appointments"
                                placeholder="Appointments"
                                value={leadsAppointment}
                                onChange={(e) => setLeadsAppointment(e.target.value)} />
                                
                            {/* {(reportType === "Qualified") &&()} */}

                                <Input
                                title="Appointments-noshow"
                                placeholder="No-show"
                                value={leadnoshow}
                                onChange={(e) => setLeadnoshow(e.target.value)} />
                                

                            
                            <Input
                                title="Hot"
                                placeholder="Hot"
                                value={leadsHot}
                                onChange={(e) => setLeadsHot(e.target.value)} />
                            <Input
                                title="Moderate"
                                placeholder="Medium"
                                value={leadsModerate}
                                onChange={(e) => setLeadsModerate(e.target.value)} />
                            <Input
                                title="Low"
                                placeholder="Low"
                                value={leadsCold}
                                onChange={(e) => setLeadsCold(e.target.value)} />
                        </div>
                    </div>

                    <div className="report-field small">
                        <h6>Profile Views</h6>
                        <div className="fields">
                            <Input
                                placeholder="Views"
                                value={profileViews}
                                onChange={(e) => setProfileViews(e.target.value)} />
                            <Input
                                placeholder="Period"
                                value={profileViewsPeriod}
                                onChange={(e) => setProfileViewsPeriod(e.target.value)} />
                        </div>
                    </div>
                </div>
            }

            {/* If there is a client loaded in and a report type set */}
            {(client?.name && reportType) &&
                <>
                    <p className="download" onClick={() => downloadReport()}>
                        Download
                    </p>

                    <div className="report-wrap">
                        {/* Monthly prospecting reports */}
                        {(reportType === "Monthly Prospecting") &&
                            <Report
                                client={client}
                                type={reportType}
                                connections={[connectionsAccepted, (connectionsSent - connectionsAccepted)]}
                                messages={[messagesEngaged, (messagesSent - messagesEngaged)]}
                                leads={[leadsAppointment, leadnoshow,leadsHot, leadsModerate, leadsCold]}
                                views={[profileViews, profileViewsPeriod]}
                            />
                        }

                        {/* Qualified reports */}
                        {(reportType === "Qualified") &&
                            <Report
                                client={client}
                                type={reportType}
                                title={reportTitle}
                                connections={[connectionsAccepted, (connectionsSent - connectionsAccepted)]}
                                messages={[messagesEngaged, (messagesSent - messagesEngaged)]}
                                leads={[leadsAppointment, leadnoshow, leadsHot, leadsModerate, leadsCold]}
                                views={[profileViews, profileViewsPeriod]}
                            />
                        }
                    </div>
                </>
            }
        </div>
    );
}

export default Reports;