import React, { useContext, useEffect } from "react";
import firebase from "firebase";
import { db } from "../../../utils/firebase";
import { OnboardingContext } from "../../../utils/providers/onboarding";
import { CheckIcon } from "../../../utils/svgs";

/**
 * Functional component saving the onboarding data into the database
 */
function Save(props) {
    /**
     * Deconstruct the onboarding data
     */
    const {
        stakeholder,
        company,
        offering,
        customer,
        competitors,
        qualification,
    } = useContext(OnboardingContext);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Save the onboarding details into the database
         */
        db.doc(`onboarding/${props.onboardingID}`).set({
            stakeholder,
            company,
            offering,
            customer,
            competitors,
            qualification,
            submitted: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true });
    }, []);

    return (
        <div className="onboarding-saving">
            <div className="large-check">
                <CheckIcon />
            </div>

            <h2>Thank You, We've Got It</h2>
            <p>Your onboarding form is now with your sales development representative and they will be in touch shortly with any questions furthering the information provided.</p>

            <hr />
            <div className="contact-details">
                <a href="tel:+442038206083">020 3820 6083</a>
                <a href="mailto:info@curral.london">info@curral.london</a>
            </div>
        </div>
    )
}

export default Save;