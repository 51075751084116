import React, { useState, createContext } from "react";

/**
 * Setup a context for storing the "add workout" modal objects
 */
const OnboardingContext = createContext();

/**
 * Creating the provider to wrap the add workout modal in for displaying
 */
function OnboardingProvider({ children }) {
    const [stage, setStage] = useState(1);
    const [stakeholder, setStakeholder] = useState({});
    const [company, setCompany] = useState({});
    const [offering, setOffering] = useState({});
    const [customer, setCustomer] = useState({});
    const [competitors, setCompetitors] = useState({});
    const [qualification, setQualification] = useState({});

    return (
        <OnboardingContext.Provider value={{
            stage, setStage,
            stakeholder, setStakeholder,
            company, setCompany,
            offering, setOffering,
            customer, setCustomer,
            competitors, setCompetitors,
            qualification, setQualification,
        }}>
            {children}
        </OnboardingContext.Provider>
    );
};

export { OnboardingContext, OnboardingProvider };