import React from "react";
import logo_big from "../../../assets/images/report-logo.png";
import footer_logo from "../../../assets/images/curral-circle.png";

/**
 * Styles
 */
import "./report.scss";

/**
 * Charts
 */
import "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";

const moment = require("moment");

const Report = (props) => {
    console.log(props, "RECORS PROPS")
    const connection_data = {
        labels: ["Accepted", "Sent"],
        datasets: [
            {
                label: "# of connections",
                data: props.connections,
                backgroundColor: ["rgba(238, 124, 81, 1)", "rgba(128, 128, 128, 1)"],
                borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
                borderWidth: 2,
            },
        ],
    };

    const message_data = {
        labels: ["Engaged", "Sent"],
        datasets: [
            {
                label: "# of messages",
                data: props.messages,
                backgroundColor: ["rgba(238, 124, 81, 1)", "rgba(128, 128, 128, 1)"],
                borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
                borderWidth: 2,
            },
        ],
    };

    let lead_data = {};

    if (props.type === "Monthly Prospecting") {
        lead_data = {
            labels: ["Appointments", "No-show", "Hot", "Moderate", "Low"],
            datasets: [
                {
                    label: "# of leads",
                    data: props.leads,
                    // backgroundColor: ["rgba(238, 124, 81, 1)", "rgba(138, 101, 86, 1)", "rgba(128, 128, 128, 1)"],
                    // borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
                    backgroundColor: ["rgba(238, 124, 81, 1)", "rgba(201, 113, 77, 1)", "rgba(138, 101, 86, 1)", "rgba(128, 128, 128, 1)"],
                    borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
                    borderWidth: 2,
                },
            ],
        };
    } else {
        lead_data = {
            labels: ["Appointments", "No-show", "Hot", "Moderate", "Low"],
            datasets: [
                {
                    label: "# of leads",
                    data: props.leads,
                    backgroundColor: ["rgba(238, 124, 81, 1)", "rgba(201, 113, 77, 1)", "rgba(138, 101, 86, 1)", "rgba(128, 128, 128, 1)"],
                    borderColor: ["rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)", "rgba(255, 255, 255, 1)"],
                    borderWidth: 2,
                },
            ],
        };
    }

    return (
        <div id="report">
            <div className="report-bg">
                <img className="top-left-img" src={logo_big} alt="Curral Logo Large" />
                <div className="title">
                    {props.type === "Monthly Prospecting" && (
                        <h1>
                            <span>{props.client.name}</span> - {moment().subtract(1, "month").startOf("month").format("MMMM")} Report
                        </h1>
                    )}
                    {props.type === "Qualified" && (
                        <h1>
                            <span>{props.client.name}</span> - {props.title}
                        </h1>
                    )}
                </div>
                <div className="stats-table">
                    <table>
                        <tbody>
                            <tr>
                                <td>Connections requests: </td>
                                <td>{parseInt(props.connections[1]) + parseInt(props.connections[0]) || 0}</td>
                            </tr>
                            <tr>
                                <td>New connections accepted:</td>
                                <td>{props.connections[0] || 0}</td>
                            </tr>
                            <tr>
                                <td>Messages sent to prospects:</td>
                                <td>{parseInt(props.messages[1]) + parseInt(props.messages[0]) || 0}</td>
                            </tr>
                            <tr>
                                <td>Engagements:</td>
                                <td>{props.messages[0] || 0}</td>
                            </tr>
                            {props.type === "Monthly Prospecting" && (
                                <tr>
                                    <td>Qualified leads:</td>
                                    <td>{props.leads[0] || 0}</td>
                                </tr>
                            )}
                            {props.type === "Qualified" && (
                                <tr>
                                    <td>Leads:</td>
                                    <td>{props.leads[1] || 0}</td>
                                </tr>
                            )}
                            {props.type === "Qualified" && (
                                <tr>
                                    <td>Appointments set:</td>
                                    <td>{props.leads[0] || 0}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="pie-charts">
                    <div className="pc pc-connections">
                        <h2>Connections Accepted</h2>
                        <div>
                            <Pie
                                data={connection_data}
                                width={220}
                                height={220}
                                options={{
                                    maintainAspectRatio: false,
                                    legend: {
                                        display: false,
                                    },
                                    tooltips: {
                                        enabled: false,
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            formatter: function (value, context) {
                                                if (context.chart.data.labels[context.dataIndex] === "Sent") {
                                                    return "";
                                                } else {
                                                    return context.chart.data.labels[context.dataIndex] + "\n" + value;
                                                }
                                            },
                                            color: "#fff",
                                            textAlign: "center",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="pc pc-messages">
                        <h2>Message Responses</h2>
                        <div>
                            <Pie
                                data={message_data}
                                width={220}
                                height={220}
                                options={{
                                    maintainAspectRatio: false,
                                    legend: {
                                        display: false,
                                    },
                                    tooltips: {
                                        enabled: false,
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            formatter: function (value, context) {
                                                if (context.chart.data.labels[context.dataIndex] === "Sent") {
                                                    return "";
                                                } else {
                                                    return context.chart.data.labels[context.dataIndex] + "\n" + value;
                                                }
                                            },
                                            color: "#fff",
                                            textAlign: "center",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="pc pc-leads">
                        <h2>Leads</h2>
                        <div>
                            <Pie
                                data={lead_data}
                                width={220}
                                height={220}
                                options={{
                                    maintainAspectRatio: false,
                                    legend: {
                                        display: false,
                                    },
                                    tooltips: {
                                        enabled: true,
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: true,
                                            formatter: function (value, context) {
                                                return context.chart.data.labels[context.dataIndex] + "\n" + value;
                                            },
                                            color: "#fff",
                                            textAlign: "center",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="profile_views">
                    {props.views[0] > 0 && (
                        <p>
                            Your profile had <span>{props.views[0]}</span> views in the past {props.views[1]}.
                        </p>
                    )}
                </div>

                <div className="link">
                    <p>Manage your leads</p>
                    <small>crm.curral.london</small>
                </div>

                <div className="footer">
                    <div className="bar"></div>
                    <div className="logo">
                        <img className="footer-logo" src={footer_logo} alt="Footer Curral Logo" />
                    </div>
                    <div className="bar"></div>
                </div>
            </div>
        </div>
    );
};

export default Report;
