



import React, { Component } from 'react';
import AddFilter from "../../forms/add-filter";
import Modal from "../modal";



class AddFilterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <Modal active={this.props.active} toggle={this.props.dismiss}>
                <h1>Filter Leads</h1>
                <AddFilter
                    active={this.props.active}
                    client={this.props.client}
                    dismiss={this.props.dismiss}
                />

            </Modal>
        )
    }
}

export default AddFilterModal