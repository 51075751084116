import React, { useContext } from "react";
import { OnboardingContext } from "../../../utils/providers/onboarding";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Textarea from "../../../components/ui/input/textarea";

/**
 * Functional component returning a stage of fields for the onboarding process
 */
function Qualification() {
    /**
     * Deconstruct the data needed from the onboarding context
     */
    const {
        stage, setStage,
        qualification, setQualification,
    } = useContext(OnboardingContext);

    return (
        <div className="onboarding-stage-window">
            <h1>Qualification / Questioning / Sales process</h1>

            <div className="ob-input-block">
                <Textarea
                    type="text"
                    placeholder="Please list 3 relevant competitors"
                    value={qualification.open_questions}
                    onChange={(e) => setQualification({
                        ...qualification,
                        open_questions: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Qualification; what do we need to qualify before we set an appointment?"
                    value={qualification.qualification}
                    onChange={(e) => setQualification({
                        ...qualification,
                        qualification: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Sales process; Curral - Close (how long is a typical sales cycle)"
                    value={qualification.sales_process}
                    onChange={(e) => setQualification({
                        ...qualification,
                        sales_process: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="How would you prefer appointments are set? (Teams/Zoom/Calendly)"
                    value={qualification.setting_preference}
                    onChange={(e) => setQualification({
                        ...qualification,
                        setting_preference: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Who will be sitting the appointments?"
                    value={qualification.who_sitting}
                    onChange={(e) => setQualification({
                        ...qualification,
                        who_sitting: e.target.value,
                    })} />
            </div>

            <Button onClick={() => setStage(stage + 1)}>
                Save &amp; Submit Form
            </Button>
        </div>
    )
}

export default Qualification;