import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './modal.scss';

const Modal = (props) => (
    <div className={['modal', props.class, props.active ? 'active' : ''].join(' ')}>
        <div className="modal-content">
            <div className="modal-close" onClick={props.toggle}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
            {props.children}
        </div>
    </div>
)

export default Modal;