import React, { useContext } from "react";
import { OnboardingContext } from "../../../utils/providers/onboarding";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Input from "../../../components/ui/input/input";
import Textarea from "../../../components/ui/input/textarea";

/**
 * Functional component returning a stage of fields for the onboarding process
 */
function Offering() {
    /**
     * Deconstruct the data needed from the onboarding context
     */
    const {
        stage, setStage,
        offering, setOffering,
    } = useContext(OnboardingContext);

    return (
        <div className="onboarding-stage-window">
            <h1>Offering (service / product)</h1>

            <div className="ob-input-block">
                <Textarea
                    type="text"
                    placeholder="What is the offering you are presenting through working with Curral?"
                    value={offering.offering}
                    onChange={(e) => setOffering({
                        ...offering,
                        offering: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Please list up to 5 key sells of the offering"
                    value={offering.key_sells}
                    onChange={(e) => setOffering({
                        ...offering,
                        key_sells: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="What's unique about the offering/business?"
                    value={offering.unique}
                    onChange={(e) => setOffering({
                        ...offering,
                        unique: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Pricing structure"
                    value={offering.pricing_structure}
                    onChange={(e) => setOffering({
                        ...offering,
                        pricing_structure: e.target.value,
                    })} />

                <Input
                    type="number"
                    placeholder="Average deal size"
                    value={offering.deal_size}
                    onChange={(e) => setOffering({
                        ...offering,
                        deal_size: e.target.value,
                    })} />
            </div>

            <Button onClick={() => setStage(stage + 1)}>
                Continue
            </Button>
        </div>
    )
}

export default Offering;