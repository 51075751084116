import React, { Component } from 'react';
import { db } from '../../utils/firebase';

/**
 * Components
 */
import Button from '../ui/button/button';
import AddStakeholder from '../forms/add-stakeholder';
import Stakeholder from './stakeholder/stakeholder';

class Stakeholders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding: false,
            client: '',
            lead: '',
            default_stakeholders: []
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
        this.checkforUpdate();
    }

    /**
     * Component update
     *
     * @function
     */
    componentDidUpdate() {
        this.checkforUpdate();
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * Check for component load
     *
     * @function
     */
    checkforUpdate = () => {
        /**
         * Get the values from props
         */
        const { client } = this.props;
        /**
         * If the prop values don't match the state
         */
        if (client !== this.state.clientID) {
            /**
             * Update teh state with the new IDs
             */
            this.setState({ clientID: client }, () => {
                /**
                 * Then fetch the engagements for these values
                 */
                this.fetchStakeholders(client);
            });
        }
    }

    /**
     * Fetch the stakeholders for a particular client
     *
     * @function
     *
     * @param {String} client ID string for the client
     */
    fetchStakeholders = (client) => {
        /**
         * Setup a snapshot listener on the leads stakeholders collection
         */
        db.collection(`clients/${client.id}/stakeholders`).onSnapshot((stakeholderSnap) => {
            /**
             * Init a new empty function on each new snapshot
             */
            let stakeholders = [...this.state.default_stakeholders] || [];
            /**
             * Loop over the stakeholders collected
             */
            stakeholderSnap.docChanges().forEach(async (change) => {
                if (change.type === 'added') {
                    stakeholders.push({ id: change.doc.id, ...change.doc.data() });
                }
                if (change.type === 'modified') {
                    stakeholders = stakeholders.filter(lead => lead.id !== change.doc.id);
                    stakeholders.push({ id: change.doc.id, ...change.doc.data() });
                }
                if (change.type === 'removed') {
                    stakeholders = stakeholders.filter(lead => lead.id !== change.doc.id);
                }
                /**
                 * Update the state defaults
                 */
                this._isMounted && this.setState({ default_stakeholders: stakeholders }, () => {
                    /**
                     * Then push them to the state
                     */
                    this.setState({ stakeholders });
                });
            });
        });
    }

    /**
     * Toggle the state to show and hide the add form
     *
     * @function
     */
    toggleAdding = () => {
        /**
         * Switch the state
         */
        this.setState({ adding: !this.state.adding });
    }

    /**
     * Remove the stakeholder from the client object
     *
     * @function
     */
    removeStakeholder = (uid) => {
        /**
         * Get the values from props
         */
        const { client } = this.props;
        /**
         * Remove the stakeholder reference on the client
         */
        db.doc(`clients/${client.id}/stakeholders/${uid}`).delete();
    }

    render() {
        // console.log("IN STAKE HOLDER VIEW")
        /**
         * If there are stakeholders loaded in the state
         */
        return (
            <div className={['modal-form', this.props.active ? 'show' : ''].join(' ')}>
                {/* Add stakeholder form toggle */}
                {!this.state.adding &&
                    <Button onClick={this.toggleAdding}>Add Stakeholder</Button>}

                {/* Add engagement form */}
                {this.state.adding &&
                    <AddStakeholder
                        client={this.props.client}
                        lead={this.props.lead}
                        active={this.state.adding}
                        dismiss={this.toggleAdding} />}

                {/* Print the stakeholders */}
                {this.state.stakeholders ? this.state.stakeholders.map((stakeholder, index) => (
                    <div key={stakeholder.id} className="stakeholder-item">
                        <Stakeholder
                            user={stakeholder.id}
                            notifications={stakeholder.notifications}
                            remove={this.removeStakeholder} />

                    </div>
                )) : <><br /><p>No stakeholders yet</p></>}
            </div>
        );
    }
}

export default Stakeholders;