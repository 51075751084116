import React, { useState, useContext, useEffect } from "react";
import { OnboardingContext } from "../../utils/providers/onboarding";
import { db } from "../../utils/firebase";
import "./onboarding.scss";

/**
 * Onboarding stages
 */
import Stakeholders from "./stages/stakeholders";
import Company from "./stages/company";
import Offering from "./stages/offering";
import Customer from "./stages/customer";
import Competitors from "./stages/competitors";
import Qualification from "./stages/qualification";
import Save from "./stages/save";

/**
 * Functional component to return the onboarding form 
 */
function Onboarding(props) {
    const [redirect, setRedirect] = useState(false);
    const [clientName, setClientName] = useState("");

    /**
     * Deconstruct the ID from the parameters
     */
    const { id } = props.match.params;

    /**
     * Deconstruct the stage vars from the context
     */
    const { stage, setStage } = useContext(OnboardingContext);

    /**
     * Setup an array to hold the total step count for looping through in the navigation
     */
    const onboardingSteps = [...Array(6).keys()].map((x) => ++x);

    /**
     * On component load
     */
    useEffect(() => {
        /**
         * Get the onboarding document from the database
         */
        db.doc(`onboarding/${id}`).get().then((onboardingDoc) => {
            /**
             * If the onboarding document exists
             */
            if (onboardingDoc.exists) {
                /**
                 * Deconsruct the client ID from the onboarding document
                 */
                const { client } = onboardingDoc.data();
                /**
                 * Then fetch the client name from the database
                 */
                db.doc(`clients/${client}`).get().then((clientDoc) => {
                    /**
                     * Get the name for the client from the document
                     */
                    const { name } = clientDoc.data();
                    /**
                     * Push the name into the state
                     */
                    setClientName(name);
                });
            } else {
                /**
                 * Redirect the user abck to the app root
                 */
                setRedirect("/");
            }
        });
    }, []);

    /**
     * When the stage udpates, scroll to the top of the page
     */
    useEffect(() => {
        /**
         * Scroll back tot he top of the page
         */
        setTimeout(() => {
            /**
             * Get the wrapper div that has the overflow on it
             */
            const onboarding_container = document.getElementById("onboarding-window");
            /**
             * Scroll to the top
             */
            onboarding_container.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }, 0);
    }, [stage]);

    /**
     * Check the stage the user is trying to navigate towards before continuing, only 
     * allowing them to move back to a stage that has previoulsy been filled out
     */
    const processNavClick = (newStage) => {
        /**
         * If the nav item clicked is in the past
         */
        if (stage >= newStage) {
            /**
             * Move back in the stage
             */
            setStage(newStage);
        }
    }

    return (
        <div id="onboarding-window">
            <div className="onboarding-width-control">
                {/* Is the onboarding form currently in progress */}
                {(stage <= 6) &&
                    <>
                        <div className="onboarding-title">
                            <h1>Curral Onboarding {clientName ? `for ${clientName}` : ""}</h1>
                            <p>We need to learn about you &amp; your business to fully optimise our delivery. Please fill in our onboarding form and you sales development representative will be in touch.</p>
                            <hr />
                        </div>
                        <div className="onboarding-steps">
                            <div className="onboarding-step-numbers">
                                {onboardingSteps.map((step) => (
                                    <div
                                        key={step}
                                        className={[
                                            "onboarding-step-number",
                                            (stage === step) && "is-active",
                                            (stage > step) && "is-past"
                                        ].join(" ")}
                                        onClick={() => processNavClick(step)}>
                                        {step}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="onboarding-stage-wrapper">
                            {(stage === 1) && <Stakeholders />}
                            {(stage === 2) && <Company />}
                            {(stage === 3) && <Offering />}
                            {(stage === 4) && <Customer />}
                            {(stage === 5) && <Competitors />}
                            {(stage === 6) && <Qualification />}
                        </div>
                    </>
                }

                {/* Is the onboarding form currently saving into the database */}
                {(stage === 7) && <Save onboardingID={id} />}
            </div>
        </div>
    );
}

export default Onboarding;