import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faSlidersH,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import "../navigation.scss";

const Navigation = (props) => (
  <>
    <div className="nav-item" onClick={() => props.navigate("clients")}>
      <FontAwesomeIcon icon={faUsers} />
    </div>
    {/* <div className="nav-item" onClick={() => props.navigate("appointments")}>
      <FontAwesomeIcon icon={faSlidersH} />
    </div>
    <div className="nav-item" onClick={() => props.navigate("reports")}>
      <FontAwesomeIcon icon={faChartPie} />
    </div> */}
  </>
);

export default Navigation;
