import React, { useContext, useEffect, useState } from "react";
import { OnboardingContext } from "../../../utils/providers/onboarding";
import * as linkedin from "../../../utils/linkedin";

/**
 * UI components
 */
import Button from "../../../components/ui/button/button";
import Input from "../../../components/ui/input/input";
import Textarea from "../../../components/ui/input/textarea";
import MultipleSelect from "../../../components/ui/select/multiple-select";

/**
 * Functional component returning a stage of fields for the onboarding process
 */
function Customer() {
    const [localIndustries, setLocalIndustries] = useState([]);

    /**
     * Deconstruct the data needed from the onboarding context
     */
    const {
        stage, setStage,
        customer, setCustomer,
    } = useContext(OnboardingContext);

    /**
     * When the local industries are udpated
     */
    useEffect(() => {
        /**
         * New array to hold industry names
         */
        const industryNames = [];
        /**
         * Loop through the chosen industries and pull the name from the option
         */
        localIndustries.forEach((industry, index) => {
            /**
             * Push to the array
             */
            industryNames.push(industry.value);
        });
        /**
         * When we're done, set them into the context
         */
        setCustomer({ ...customer, industries: industryNames });
    }, [localIndustries]);

    return (
        <div className="onboarding-stage-window">
            <h1>Customer Profiling</h1>

            <div className="ob-input-block">
                <Textarea
                    type="text"
                    placeholder="What types of companies do you work with? Looking to target? Name a client? (if allowed)"
                    value={customer.companies}
                    onChange={(e) => setCustomer({
                        ...customer,
                        companies: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Any particular Job titles, Decision makers or Influencers we should target?"
                    value={customer.job_titles}
                    onChange={(e) => setCustomer({
                        ...customer,
                        job_titles: e.target.value,
                    })} />

                <MultipleSelect
                    placeholder="What industries do you want to target (choose from dropdown)"
                    onSelect={setLocalIndustries}
                    options={linkedin.industries} />

                <Textarea
                    type="text"
                    placeholder="What locations should we target?"
                    value={customer.locations}
                    onChange={(e) => setCustomer({
                        ...customer,
                        locations: e.target.value,
                    })} />

                <Input
                    type="text"
                    placeholder="What company headcount should we target? (LinkedIn headcount)"
                    value={customer.headcount}
                    onChange={(e) => setCustomer({
                        ...customer,
                        headcount: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Please provide 3 potential pain points for clients and the solutions you offer"
                    value={customer.pain_points}
                    onChange={(e) => setCustomer({
                        ...customer,
                        pain_points: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="Who is influenced by the pain points?"
                    value={customer.influenced_by_pain_points}
                    onChange={(e) => setCustomer({
                        ...customer,
                        influenced_by_pain_points: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="What additional insight could we provide to you that you would deem valuable when speaking to prospective customers?"
                    value={customer.additional_insights}
                    onChange={(e) => setCustomer({
                        ...customer,
                        additional_insights: e.target.value,
                    })} />

                <Textarea
                    type="text"
                    placeholder="What are the most common rejections your business experiences?"
                    value={customer.common_rejections}
                    onChange={(e) => setCustomer({
                        ...customer,
                        common_rejections: e.target.value,
                    })} />
            </div>

            <Button onClick={() => setStage(stage + 1)}>
                Continue
            </Button>
        </div>
    )
}

export default Customer;