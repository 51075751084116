import React, { useState } from "react";
import { fun } from "../../utils/firebase";
import "./settings.scss";

/**
 * UI components
 */
import Button from "../../components/ui/button/button";
import Input from "../../components/ui/input/input";

/**
 * Functional component to return the settings page for admins
 */
function Settings() {
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [addingUser, setAddingUser] = useState(false);

    /**
     * Run a cloud function to add the new user
     */
    const addNewUser = async () => {
        /**
         * Change the button text to adding
         */
        setAddingUser(true);
        /**
         * Call teh cloud function to make the new user account
         */
        const createUser = fun.httpsCallable("createStaffAuthAccount");
        const userResult = await createUser({
            name: userName,
            email: userEmail,
            password: userPassword,
        });
        /**
         * Reset the button text & state
         */
        setAddingUser(false);
        setUserName("");
        setUserEmail("");
        setUserPassword("");
        /**
         * Return the resulting user document
         */
        return userResult;
    }

    return (
        <div id="settings-window">
            <>
                <h1>CRM Settings</h1>
                <hr />
            </>

            <div className="add-user-account">
                <p>Add new staff account</p>

                <Input
                    type="text"
                    placeholder="Name:"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)} />

                <Input
                    type="email"
                    placeholder="Email:"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)} />

                <Input
                    type="password"
                    placeholder="Password:"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)} />

                <Button
                    onClick={() => addNewUser()}
                    disabled={!userName || !userEmail || !userPassword || addingUser}>
                    {addingUser ? "Adding..." : "Add User"}
                </Button>
            </div>
        </div>
    )
}

export default Settings;