import React, { Component } from 'react';

/**
 * Components
 */
import AddClient from '../../forms/add-client';

/**
 * Modal wrapper
 */
import Modal from '../modal';

class AddClientModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {}
        };
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * Component unmount
     *
     * @function
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <Modal active={this.props.active} toggle={this.props.dismiss}>
                <h1>Add Client</h1>

                {/* Add client form */}
                <AddClient
                    client={this.state.client}
                    dismiss={this.props.dismiss} />
            </Modal>
        );
    }
}

export default AddClientModal;