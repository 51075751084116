import React from 'react'

const Sdr_dash_icon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23.7325 7.02749C19.0288 13.9625 10.31 16.2112 10.31 16.2112C9.98876 16.2935 9.64801 16.2447 9.36272 16.0757C9.22146 15.992 9.09806 15.8813 8.99958 15.7499C8.9011 15.6185 8.82946 15.4691 8.78875 15.31C8.74804 15.1509 8.73906 14.9854 8.76232 14.8229C8.78558 14.6603 8.84063 14.504 8.92431 14.3627C9.09333 14.0774 9.36876 13.871 9.69 13.7887C9.69 13.7887 17.9925 11.685 22.0513 5.02124L19.5925 4.99999C19.261 4.99701 18.9442 4.86245 18.7119 4.62592C18.4796 4.38939 18.3508 4.07026 18.3537 3.73874C18.3567 3.40722 18.4913 3.09046 18.7278 2.85815C18.9644 2.62584 19.2835 2.49701 19.615 2.49999L24.965 2.54749C25.2928 2.54978 25.6066 2.68103 25.8384 2.91284C26.0702 3.14466 26.2015 3.45841 26.2037 3.78624L26.25 9.01249C26.253 9.34401 26.1241 9.66314 25.8918 9.89967C25.6595 10.1362 25.3428 10.2708 25.0113 10.2737C24.6797 10.2767 24.3606 10.1479 24.1241 9.91558C23.8875 9.68327 23.753 9.36651 23.75 9.03499L23.7325 7.02749ZM13.1062 37.5H7.5C6.83696 37.5 6.20107 37.2366 5.73223 36.7678C5.26339 36.2989 5 35.663 5 35V23.75C5 23.0869 5.26339 22.4511 5.73223 21.9822C6.20107 21.5134 6.83696 21.25 7.5 21.25H13.1062V37.5ZM15.6062 20.0437C15.6062 18.6387 16.745 17.5 18.15 17.5H21.7463C23.1512 17.5 24.29 18.6387 24.29 20.0437V37.5H15.6062V20.0437ZM26.79 15.0437C26.79 13.6387 27.9287 12.5 29.3325 12.5H32.5C33.163 12.5 33.7989 12.7634 34.2678 13.2322C34.7366 13.7011 35 14.3369 35 15V35C35 35.663 34.7366 36.2989 34.2678 36.7678C33.7989 37.2366 33.163 37.5 32.5 37.5H26.79V15.0437Z" 
            fill="#EE7C51" 
            />
        </svg>
    )
}

export default Sdr_dash_icon