import React, { Component } from 'react';
import firebase from 'firebase';
import { db, auth } from '../../utils/firebase';
import { lead_types, lead_origin } from '../../utils/selects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

// import moment from 'moment';
import 'moment/locale/de';
/**
 * Datetime picker
 */
import Datetime from 'react-datetime';

/**
 * Components
 */
import Input from '../ui/input/input';
import TextArea from '../ui/input/textarea';
import Select from '../ui/input/select';
import Button from '../ui/button/button';
import CustomSelect from '../ui/input/customSelect';

/**
 * Moment libary
 */
const moment = require('moment');





class AddFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date: "",
            init_engagement: Datetime.moment(),
            type: "",
            RecordedBy: "",
            users: []
        }
    }

    /**
     * Component mount
     *
     * @function
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.clickedOutsideWhereSelect);
        document.addEventListener('mousedown', this.clickedOutsideTypeSelect);
        this.fetchUsers();
    }


    /**
   * Component mount
   *
   * @function
   */
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickedOutsideWhereSelect);
        document.removeEventListener('mousedown', this.clickedOutsideTypeSelect);
    }

    /**
        * Wrapper refs for the lead origin select field
        */
    setWhereRef = node => {
        this.whereRef = node;
    }
    clickedOutsideWhereSelect = (e) => {
        if (this.whereRef && !this.whereRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_where: false
            });
        }
    }

    toggleWhereSelect = () => {
        this.setState({
            selecting_where: !this.state.selecting_where
        });
    }

    /**
     * Wrapper refs for the type select field
     */
    setTypeRef = node => {
        this.typeRef = node;
    }

    setNameRef = node => {
        console.log(node, "NODE")
        this.nameRef = node
    }
    clickedOutsideTypeSelect = (e) => {
        if (this.typeRef && !this.typeRef.contains(e.target)) {
            this.setState({
                ...this.state,
                selecting_type: false
            });
        }
    }

    toggleTypeSelect = () => {
        this.setState({
            selecting_type: !this.state.selecting_type
        });
    }

    toggleNameSelect = () => {
        this.setState({
            selecting_name: !this.state.selecting_name
        })
    }
    /**
    * Handle the event of an input change
    *
    * @function
    *
    * @param {Object} e Event object
    */
    handleInputChange = (e) => {
        /**
         * Update the state with the field
         */
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    /**
   * Update the state with a formatted verison of the datetime picker result
   *
   * @function
   *
   * @param {Object} date Date object from the datetime picker
   */
    handleDateChange = (date) => {
        /**
         * Parse the date into different string types
         */
        const readable = moment(date).format('DD/MM/YYYY HH:mm');
        const seconds = moment(date).format('X');
        /**
         * Then set the state with them
         */
        this.setState({
            ...this.state,
            init_engagement: {
                seconds: seconds,
                readable: readable
            }
        });

    }
    /**
     * Handle the change of the lead type
     *
     * @function
     *
     * @param {Object} value Object containing the readable and variable for the selected item
     */
    leadTypeChanged = (value) => {
        this.setState({
            ...this.state,
            type: value.value,
            selecting_type: false
        });
    }


    nameChange = (value) => {
        this.setState({
            ...this.state,
            name: value.value,
            selecting_name: false
        })
        // console.log(value)
    }
    fetchUsers = () => {
        console.log("HIHIHIHIHIH")
        db.collection(`users`).get().then((userSnap) => {
            // console.log(userSnap,"SNPASNDPASDNN")
            userSnap.forEach(((usr) => {
                this.setState(state => {
                    return {
                        ...state,
                        users: [...state?.users, {
                            id: usr.id,
                            name: usr.data().name
                        }]
                    }
                })
            }));
            // console.log(this.state)
            // if(userSnap.exists){
            //     this.setState({users:{
            //         id: userSnap.id
            //     }})
            // }
        })
    }
    show = () => {
        console.log(this.state)

    }
    render() {
        return (
            <div className={['modal-form show'].join(' ')} >

                <div className="inputfield" ref={this.setNameRef} >

                    <CustomSelect 
                        placeholder="Name"
                        options={this.state?.users.map(( usr) => usr.name)}
                        value={this.state.name}
                        changed={this.nameChange}
                        toggled={this.state.selecting_name}
                        toggle={this.toggleNameSelect}
                        users={this.state.users}
                    />
                </div>

                {/* <div className="ib-select" ref={this.nameRef}>

                <select onChange={this.nameChange} >
                        {this.state?.users ?(<>
                        {
                            this.state.users.map((usr)=>{
                                return (
                                    <option value={usr.id} >{usr.name}</option>
                                )
                            })
                        }
                        </>) :(<></>)}
                    </select>
                </div> */}

                <div className="inputfield" ref={this.setTypeRef} >
                    <Select
                        placeholder="Lead type"
                        options={lead_types}
                        value={
                            this.state.type &&
                            this.state.type &&
                            this.state.type.substring(2)
                        }
                        changed={this.leadTypeChanged}
                        toggled={this.state.selecting_type}
                        toggle={this.toggleTypeSelect} />
                </div>
                <div>
                    <label>Date From:</label>
                    <Datetime
                        locale='en'
                        // value={this.state.date}
                        timeFormat={true}
                        placeholder={"Date"}
                        onChange={date => this.handleDateChange(date)}
                    />
                    <Button onClick={this.show}>Search</Button>
                </div>

            </div>
        )
    }

}

export default AddFilter;